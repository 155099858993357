import Vue from 'vue'
import HomePage from './Home-Page.vue'
import BrandItem from './components/Brand-Item.vue'
import BrandOnboarding from './components/Brand-Onboarding.vue'
//import WalletSettings from './components/Wallet-Settings.vue'
import ProSetup from './components/Pro-Setup.vue'
import MySales from './components/My-Sales.vue'
import MyLinks from './components/My-Links.vue'
import MyPosts from './components/My-Posts.vue'
import MyStoreItems from './components/My-Store-Items.vue'
import MyPurchases from './components/My-Purchases.vue'
import StoreView from './components/Store-View.vue'
import WalletType from './components/Wallet-Type.vue'
import NotificationSetup from './components/Notification-Setup.vue'
import BrandView from './components/Brand-View.vue'
import BrandAll from './components/Brand-All.vue'
import BrandGroup from './components/Brand-Group.vue'
import AdminLoyalty from './components/Admin-Loyalty.vue'
import HomeContent from './components/Home-Content.vue'
import LoginCreate from './components/Login-Create.vue'
import vuetify from "./plugins/vuetify";
import store from "./plugins/vuex";
import VueRouter from 'vue-router'
import { VueBus } from './plugins/vuebus.js';


Vue.use(VueRouter)

const routes = [
  { 
    path: '/brands', 
    component: BrandAll,
    children:[
      { name: 'root', path: '',  component:  BrandGroup },
      { name: 'login', path: '/login', component: LoginCreate},
      { name: 'create', path: '/create', component: LoginCreate},    
      { name: 'settings', path: '/settings', component: ProSetup},
      { name: 'sales', path: '/sales', component: MySales},
      { name: 'links', path: '/links', component: MyLinks},
      { name: 'posts', path: '/posts', component: MyPosts},
      { name: 'storeitems', path: '/storeitems', component: MyStoreItems},
      { name: 'purchases', path: '/purchases', component: MyPurchases},
      { name: 'account_type', path: '/account_type', component: WalletType},
      { name: 'notifications', path: '/notifications', component: NotificationSetup},
      { name: 'loyalty', path: '/loyalty', component: AdminLoyalty},
      { name: 'brandsonboarding', path: '/brandsonboarding', component: BrandOnboarding, props: true},
      { name: 'post', path: '/brands/:brandID/post/:postID', component: BrandItem},
      { name: 'store', path: '/brands/:brandID/store', component: StoreView},          
      { name: 'storebuy', path: '/brands/:brandID/store/:storeItemID/buy', component: StoreView},          
      { name: 'storeitem', path: '/brands/:brandID/store/:storeItemID', component: StoreView},          
      { name: 'brand', path: '/brands/:brandID', component: BrandView},          
    ] 
  },
  { path: '/', component: HomeContent},

]

const router = new VueRouter({
  mode: "history", 
  routes // short for `routes: routes`
})


Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(HomePage),
}).$mount('#app')

router.beforeEach(async (to, from, next) => {
  let walletID = await store.dispatch("fetchWalletID")
//  VueBus.$emit("Nav", "/brands" )
  
  switch(to.path){
    case "/posts":
    case "/links":
    case "/store":
    case "/sales":

      var tokens = await store.dispatch('fetchTokens', walletID );

      if(tokens.length == 0){
        console.log("Meow")
        next(false) 
        VueBus.$emit("Nav", "/brandsonboarding","OK")  
        return;
      }
      next()
      break;
      
    default:
      next()  
      return;
    //VueBus.$emit("Nav", to.path)  
  }
  
  
  
})

