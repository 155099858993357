<template>
    <v-container class="fill-height" color="purple">
        <v-row  class="fill-height">
            <v-col  class="fill-height">
                <v-card class="fill-height" style="background-color:white; margin-top:45px;">
                    <v-card-title>
                        My Sales
                    </v-card-title>
                    <v-card-text>
                        <v-sheet v-if="sales.length == 0">
                                No sales have been happend yet.
                        </v-sheet>


                        <v-expansion-panels v-if="sales.length > 0" popout>
                            <v-expansion-panel @click="ExpandPanel(item)"
                                v-for="item in sales" :key="item.txID">
                                <v-expansion-panel-header><v-container>
                                    <v-row>
                                        <v-col>{{ getName(item.buyerID) }}</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col align="end">{{ getDate(item.txDate) }}</v-col>
                                    </v-row>
                                </v-container> </v-expansion-panel-header>
                                <v-expansion-panel-content color="#dddddd">
                                    <v-container>
                                        <v-row>
                                            <v-col><span class="text-h6">Order:</span></v-col>
                                            <v-col align="end" ><a target="new" :href="'https://algoexplorer.io/tx/' + item.txID">Tx</a></v-col>
                                        </v-row>
                                    </v-container>
                                    <v-sheet elevation="2" color="white" oultined>
                                    <v-container>
                                        <v-row>
                                            <v-col></v-col>
                                            <v-col>Item</v-col>
                                            <v-col align="end">Amount</v-col>
                                        </v-row>
                                        <v-row v-for="product in item.items" :key="product.productID" style="border-top: 1px solid #dddddd;">
                                            <v-col>
                                                <v-avatar size="56">
                                                    <v-img 
                                                        :src="GetStoreURL(product.bgImage)"></v-img>
                                                </v-avatar>
                                            </v-col>
                                            <v-col align="start" class="text-subtitle-1">
                                                {{ product.title }}
                                            </v-col>
                                            <v-col align="end">
                                                {{ product.amount }} {{ product.asaName }}
                                            </v-col>
                                        </v-row>
                                        
                                    </v-container>
                                    </v-sheet>

                                    <div style="margin: 10px;" v-if="item.status==0">
                                        <v-container>
                                            <v-row>
                                                <v-col>
                                                    <div class="font-weight-bold">Shipping Address</div>
                                                    <div v-html="GetAddress()"></div>
                                                </v-col>
                                                <v-col align="end">
                                                    <v-btn small @click="MarkComplete(item)">Mark as complete</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        

                                        <div style="margin-top:25px;">
                                            <div>Notes</div>
                                            <v-textarea v-bind="note" v-model="note" label="Record Note" background-color="white" outline></v-textarea>
                                            <v-btn @click="SaveNote(item)">Save</v-btn>

                                            <v-list two-line dense color="transparent">                                            
                                                <v-list-item v-for="note in notes" :key="note.saveDate">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ getDateStr(note.saveDate) }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-html="note.note"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </v-list>
                                        </div>

                                    </div>

                                    <div style="margin: 10px;" v-if="item.status==1">
                                        <v-container>
                                            <v-row>
                                                <v-col>
                                                </v-col>
                                                <v-col align="end">
                                                    <v-btn small @click="MarkIncomplete(item)">Mark as incomplete</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        

                                        <div style="margin-top:25px;">
                                            <div>Notes</div>
                                            <v-textarea v-bind="note" v-model="note" label="Record Note" background-color="white" outline></v-textarea>
                                            <v-btn @click="SaveNote(item)">Save</v-btn>


                                            <v-list two-line dense color="transparent">                                            
                                                <v-list-item v-for="note in notes" :key="note.saveDate">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ getDateStr(note.saveDate) }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-html="note.note"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </div>

                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>                    </v-card-text>
                </v-card>
                
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VueBus } from '../plugins/vuebus.js';
import * as moment from 'moment'
import axios from "axios";



export default {
    name: "MySales",
    props:["addr","sessionGuid","sessionTxID","action"],
    data: () => {
      return {
        sales: [],
        address: null,
        note: "",
        notes: null,
        path: "https://worker.hokr.workers.dev/api/",
      }
    },
    watch:{
        addr(oldv, newv){
            if(oldv != newv){
                if(!this.addr){
                    VueBus.$emit("Nav","/brands")
                }
                this.LoadSales();
            }
        }
    },
    async mounted(){
        this.LoadSales();
    },
    methods:{
        getName(buyerID){
            return buyerID;
        },
        async LoadSales(){
            this.sales = await this.$store.dispatch('fetchSales', this.addr.toUpperCase() )
        },
        getDate(dateVal){
            return moment.unix(dateVal).fromNow();
        },
        getDateStr(dateVal){
            return moment(dateVal).fromNow();
        },
        getStatus(status){
            switch(status){
                case 0:
                    return "new"
                case 1:
                    return "waiting"
                case 2:
                    return "complete"

            }
        },
        GetStoreURL(cid){
            let url = "https://worker.hokr.workers.dev/api/storeimages/"+ cid
            return url;
        },
        async ExpandPanel(item){
            this.address = null;
            this.note = "";
            this.notes = await this.$store.dispatch('fetchNotes', item.txID )             
            
            console.log(this.notes)

            if(item.status==0){
                this.address = await this.$store.dispatch('fetchAddress', item.buyerID )             
            }
        },
        GetAddress(){
            let addressHTML = "";
            if(this.address){
                addressHTML += "<div>" + this.address.name + "</div>"
                addressHTML += "<div>" + this.address.address1 + "</div>"
                
                if(this.address2){
                    addressHTML += "<div>" + this.address.address1 + "</div>"
                }
                addressHTML += "<div>" + this.address.city + ", "+ this.address.city +" "+ this.address.zip +"</div>";

                return addressHTML
            } else {
                addressHTML = "Address not loaded."
            }
        },
        SaveNote(item){
            console.log(item)

            let params = {
                saveDate: new Date(),
                addr: this.addr,
                note: this.note
            }
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
            }
            console.log(params)

            var me = this;
            
            axios.post( this.path + "tx/" + item.txID + "/notes", params, { headers: headers} )
                .then(async function (response) {
                console.log(me)
                console.log(response.data)
            })
            .catch(function(error){
                console.log("Error on request")
                console.log(error)
            })

        },
        MarkComplete(item){
            console.log(item)

            let params = {
            }
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
            }

            var me = this;
            var url = this.path + "tx/" + item.txID + "/complete"
            console.log(url)

            axios.post( url, params, { headers: headers} )
                .then(async function (response) {
                item.status = 1
                console.log(me)
                console.log(response.data)
            })
            .catch(function(error){
                console.log("Error on request")
                console.log(error)
            })

        },
        MarkIncomplete(item){
            console.log(item)

            let params = {
            }
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
            }

            var me = this;
            var url = this.path + "tx/" + item.txID + "/incomplete"
            console.log(url)

            axios.post( url, params, { headers: headers} )
                .then(async function (response) {
                    item.status = 0

                    console.log(me)
                console.log(response.data)
            })
            .catch(function(error){
                console.log("Error on request")
                console.log(error)
            })

        },

    }
}
</script>