<template>  
  <v-sheet>
    <v-card>
    </v-card>
      <div class="text-caption ma-6 text-left">
        Let's create an account for you. It should only take about 30 seconds.
      </div>

    <v-stepper v-model="e1" vertical flat transparent>
        <v-stepper-items>

        <!-- v-stepper-step
            :complete="e1 > 1"
            step="1"
        >
            Pick your membership card
        </v-stepper-step>

        <v-stepper-content step="1">
            <v-card
            class="mb-12"
            >
            <v-card-text>
            <PickNFT 
                @SetName="SetName"
                @SetSelectedCard="SetSelectedCard"></PickNFT>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            @click="e1 = 2"
            :disabled="NextCard()"
            >
            Next
            </v-btn>

            </v-card-actions>
        </v-card>


        </v-stepper-content -->

        <v-stepper-step
            :complete="e1 >= 1"
            step="1"
        >
            Your Account Detals
        </v-stepper-step>                  
        
        <v-stepper-content step="1">
            <v-card
            class="mb-12"                      
            >
            <v-card-title>Details</v-card-title>
            <v-card-text>
            <AuthCreds
                @SetName="SetName"
                @SetUsername="SetUsername"
                @SetPassword="SetPassword"
            ></AuthCreds>

            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            
            <!-- v-btn 
                @click="e1 = 1"
                text>
                Previous
            </v-btn -->

            <v-btn
                color="primary"
                @click="e1 = 2"
            >
                Continue
            </v-btn>

            </v-card-actions>
        </v-card>


        </v-stepper-content>

        <v-stepper-step
            :complete="e1 > 2"
            step="2"
        >
            Your Account Wallet
        </v-stepper-step>                  
        
        <v-stepper-content step="2">
            <v-card
            class="mb-12"                      
            >
            <v-card-title>Wallet</v-card-title>
            <v-card-text>
            <CreateWallet
                @SetWallet="SetWallet"
                @SetName="SetName"
                @SetUsername="SetUsername"
                @SetPassword="SetPassword"
                ></CreateWallet>

            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn 
                @click="e1 = 1"
                text>
                Previous
            </v-btn>

            <v-btn
                color="primary"
                @click="e1 = 3"
            >
                Continue
            </v-btn>

            </v-card-actions>
        </v-card>


        </v-stepper-content>



        <v-stepper-step
            :complete="e1 > 3"
            step="3"
        >
            Preferences
        </v-stepper-step>

        <v-stepper-content step="3">
            <v-card
            class="mb-12"
            
            >
            <SelectCategories
            @SetCategories="SetCategories"
            ></SelectCategories>
            <v-card-actions>
            <v-spacer></v-spacer>


            <v-btn 
                @click="e1 = 2"
                text>
                Previous
            </v-btn>

            <v-btn
                color="primary"
                @click="e1 = 4"
                :disabled="NextCategories()"
            >
                Continue
            </v-btn>
            </v-card-actions>
        </v-card>


        </v-stepper-content>





        <v-stepper-step
            :complete="e1 > 4"
            step="4"
        >
            Notifications
        </v-stepper-step>

        <v-stepper-content step="4">
            
            <v-card
            class="mb-12"
            >
            <v-card-text class="pa-0 ma-0">
            <NotificationSetup
              @SetEmail="SetEmail"
              @SetEmailPref="SetEmailPref"
              @SetSms="SetSms"
              @SetSmsPref="SetSmsPref"
            ></NotificationSetup>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
            text
            @click="e1 = 3"
            >
            Previous
            </v-btn>
            
            <v-btn
            color="primary"
            @click="e1 = 5"
            :disabled="NextNotification()"
            >
            Continue
            </v-btn>



        </v-card-actions>
        </v-card>

        </v-stepper-content>
        <v-stepper-step
            :complete="e1 > 5"
            step="5"
        >
            Confirm Signup
        </v-stepper-step>

        <v-stepper-content step="5">
            
            <v-card
            class="mb-12"
            >
            <v-card-text class="pa-0 ma-0">
            <AccountConfirmation
                :name="name"
                :selectedCategories="selectedCategories"
                :addr="addr"
                :mnemonicPhrase="mnemonicPhrase"
                :email="email"
                :sms="sms"
            >                        
            </AccountConfirmation>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
            text
            @click="e1 = 4"
            >
            Prev
            </v-btn>
            
            <v-btn
            color="primary"
            @click="Save"
            >
            Save
            </v-btn>



        </v-card-actions>
        </v-card>
        </v-stepper-content>                  
        </v-stepper-items>
    </v-stepper>

    <v-snackbar
      v-model="notice"
      :color="noticeColor"
      dismissible
      prominent
      timeout="4000"
      right
                  
      transition="scale-transition"
      align="right"
    >
      {{noticeText}}
    </v-snackbar>

    <v-dialog
      persistent
      v-model="dialog"
      dark
      style="background-color:black;"
      color="black"
      width="500"
    >
      <div>
        <v-card style="background-color:black; height:500px;">
          <v-card-text>
        <div id="loop" class="center"></div>
        <div id="bike-wrapper" class="center">
          <div id="bike" class="centerBike"></div>
        </div>

        <div style="color:#E2DB58; margin-top:70px; font-size:48px; text-align:center;font-family:Lobster">
          Workin' on it.
        </div>
        <div style="margin-top:250px; color:#fff;">
          <div style="margin-bottom:20px; text-align:center;">While you wait, this is a great time to download a wallet.<br/> Download one of these to get started.</div>
          <v-container>
            <v-row>
              <!-- v-col align="center">      
                <v-btn raised color="yellow" large href="https://perawallet.app/" target="_blank">
                  <v-img src="../pera-logo-black.png" width="80"  class="btn btn-success" ></v-img>
                </v-btn>
              </v-col>
              <v-col align="center">
                <v-btn raised color="white" large href="https://defly.app/"  target="_blank">
                  <v-img src="../defly.png" width="80"  class="btn btn-success" ></v-img>
                </v-btn>
              </v-col -->
            </v-row>
          </v-container>
        </div>
      </v-card-text>
      </v-card>
      </div>
    </v-dialog>

  </v-sheet>  
</template>
  
  <script>
//  import PickNFT from "./onboarding/Pick-NFT.vue";
  import AuthCreds from "./onboarding/Auth-Creds.vue";
  import CreateWallet from "./onboarding/Create-Wallet.vue";
  import NotificationSetup from "./onboarding/Notification-Setup.vue";
  import SelectCategories from "./onboarding/Select-Categories.vue";
  import AccountConfirmation from "./onboarding/Account-Confirmation.vue";
  import getBrowserFingerprint from 'get-browser-fingerprint';
  import { VueBus } from '../plugins/vuebus.js';


  import axios from "axios";
  
  export default {
    name: "WalletCreate",
    props: ["action","addr","sessionGuid","sessionTxID"],

    components: {
//      PickNFT,
      AuthCreds,
      CreateWallet,
      NotificationSetup,
      SelectCategories,
      AccountConfirmation
    },
    data: () => {
      return {
        e1: 1,
        notice: false,
        noticeColor: "",
        noticeText: "",
        name: "",
        selectedCard: "",
        selectedCategories: "",
        mnemonicPhrase: "",
        walletID: "",
        email: "",
        emailPref: true,
        sms: "",
        smsPref: true,
        dialog: false
      }
    },
    async mounted(){
      
    },  
    methods: {
      NextCard(){
        var myBool = (this.name && this.selectedCard >= 0)
        return !myBool
      },
      NextCategories(){
        var myBool = this.selectedCategories.length > 0
        return !myBool
      },
      NextNotification(){
        var myBool = (this.email == "" || this.sms == "")
  
        return myBool
      },
  
      CloseCreate(){
        this.create = false;
        this.UPDATE_REFRESH++;
      },
      SetName(val){
        this.name = val;
      },
      SetUsername(val){
        this.username = val;
      },
      SetPassword(val){
        this.password = val;
      },
      SetSelectedCard(val){
        this.selectedCard = val;
      },
      SetCategories(val){
        this.selectedCategories = val;
      },
      SetWallet(val){
        this.walletID = val.addr;
        this.mnemonicPhrase = val.mnemonic;
      },
      SetEmail(val){
        this.email = val;
      },
      SetEmailPref(val){
        this.emailPref = val;
      },

      SetSms(val){
        this.sms = val;
      },
      SetSmsPref(val){
        this.smsPref = val;
      },
      GetHeaders(view){
          var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view':view
            }
            return headers;
      },
      Save(){
        this.dialog = true;
        //window.setTimeout("location.href='app'", 15000)
        var payload = {
          name: this.name,
          username: this.username,
          password: this.password,
          id: getBrowserFingerprint(),
          categories: this.selectedCategories,
          addr: this.walletID,
          mnemonic: this.mnemonicPhrase,
          email: this.email,
          emailPref: this.emailPref,
          sms: this.sms,
          smsPref: this.smsPref,
          userType: "user"

        }
        
        //break this into two chunks, wallet creation and login 
        
        //and everything else.

        console.log(JSON.stringify(payload))
        var me = this;

        axios.post( "/.netlify/functions/Onboarding",payload, { headers: this.GetHeaders("createaccount")} )
              .then(async function (response) {

                me.dialog = false;
                let d = response.data

                VueBus.$emit("AuthComplete", d)
                this.$emit("CloseCreate")
                console.log(response.data)
              })
  
      }
  
      
    }
  }
  
  </script>
  
  <style>
  .icon-spinner {
      animation: spin-animation 1s infinite;
      display: inline-block;
  }
  
  @keyframes spin-animation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(359deg);
      }
  }
  
  .HOKRHeroHead{
      font-size: 72px;
      font-family:Lobster;
      
    }
    .HOKRHeroSubHead{
      font-size: 30px;
      font-family:Poppins;
    }
    .HOKRSubHead{
      font-size: 24px;
      font-family:Poppins;
      font-weight:bold;
      padding-top:30px;
      color:aquamarine;
    }
    .HOKRText{
      font-size: 18px;
      font-family:Poppins;
      color: white;
    }
  
    .HOKRSection{
      padding-top:40px;
      padding-bottom:40px;
    }
  
  
  .centerBike {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 26px;
    margin-left: -12px;
  }
  
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }
  
  #loop {
    height: 100px;
    width: 100px;
    border: #24C4EB solid 4px;
    border-radius: 200px;
  }
  
  #loop:before {
    background: linear-gradient(to left, rgba(187, 95, 39, 0) 0%, rgba(36, 196, 235, 1) 30%, rgba(36, 196, 235, 1) 70%, rgba(187, 95, 39, 0) 100%);
    content: "";
    display: block;
    height: 4px;
    left: -100px;
    position: relative;
    top: 96px;
    width: 300px;
  }
  
  #bike-wrapper {
    height: 108px;
    width: 108px;
    animation: drive 3s linear infinite;
  }
  
  #bike {
    height: 24px;
    width: 25px;
    background-image: url("../squatch.png");
  }
  
  @keyframes drive {
    0% {
      margin-left: -364px;
      opacity: 0;
    }
    33.33% {
      transform: rotate(0deg);
      margin-left: -50px;
      opacity: 1;
    }
    66.66% {
      transform: rotate(-360deg);
      margin-left: -50px;
      opacity: 1;
    }
    100% {
      margin-left: 264px;
      transform: rotate(-360deg);
      opacity: 0;
    }
  }
  </style>
  