<template>
    <span>
        <v-container class="fill-height" color="purple">
            <v-row  class="fill-height">
                <v-col  class="fill-height">
                    <v-card class="fill-height" style="background-color:white; margin-top:45px;">
                        <v-card-title>
                            My Posts
                        </v-card-title>
                        <v-card-text>
                            <v-expansion-panels popout>
                                <v-expansion-panel
                                    v-for="item in posts" :key="item.id"
                                    @click="ExpandPanel(item)"
                                    >
                                    <v-expansion-panel-header>                                        
                                        <span class="header">
                                            <v-icon class="icon">mdi-post-outline</v-icon>
                                            {{ item.message.title }}</span>
                                        <v-spacer></v-spacer>
                                        {{ GetFromNow(item.createdDate) }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        
                                        <v-tabs
                                            v-model="selectedTab"
                                            left
                                            slider-color="orange lighten-3"
                                            >
                                            <v-tab key="preview">
                                                Preview
                                            </v-tab>
                                            <v-tab key="analytics">
                                                Analytics
                                            </v-tab>

                                            <v-tab-item
                                            key="preview">
                                                <PostItem
                                                showIcon="false"
                                                :addr="addr"
                                                :sessionGuid="sessionGuid"
                                                :sessionTxID="sessionTxID"
                                                style="background-color:#fff;"
                                                v-bind:key="item.id"
                                                :postItem="item"
                                                :brand="brand"
                                                ></PostItem>
                                            </v-tab-item>


                                            <v-tab-item
                                            key="analytics">
                                                <v-toolbar floating>
                                                    <v-btn icon @click="DeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                                                    <v-btn icon @click="ActivateItem(item)" v-if="item.status=='' || item.status=='new' || item.status=='NEW' || item.status=='ACTIVE'"><v-icon>mdi-eye-off</v-icon></v-btn>
                                                    <v-btn icon @click="DeactivateItem(item)" v-if="item.status=='INACTIVE'"><v-icon>mdi-eye-outline</v-icon></v-btn>

                                                </v-toolbar>


                                                <v-container full-width>
                                                    <v-row>
                                                        <v-col>
                                                            <v-card>
                                                                <v-card-title>
                                                                    <v-icon>mdi-web</v-icon>
                                                                    Engagement</v-card-title>
                                                                <v-card-text>
                                                                    <div class="text-h6">Overall</div>
                                                                    <v-simple-table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th width="50%">Metric</th>
                                                                                <th width="50%">Count</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Post Impressions</td>
                                                                                <td>{{ item.impressions }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Likes</td>
                                                                                <td>{{ item.likeCount }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Redeems</td>
                                                                                <td>{{ item.paidCount }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </v-simple-table>

                                                                    <div class="text-h6">
                                                                        <v-icon>mdi-email-outline</v-icon>
                                                                        Notification</div>
                                                                    <v-simple-table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th width="50%">Metric</th>
                                                                                <th width="50%">Count</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Delivered</td>
                                                                                <td>{{ eventTotal.delivered }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Opens</td>
                                                                                <td>{{ eventTotal.open }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Clicks</td>
                                                                                <td>{{ eventTotal.click }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </v-simple-table>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>


                                                </v-container>
                                            </v-tab-item>

                                        </v-tabs>
                                        
                                        




<!--
                                        <v-container>
                                            <v-row>
                                                <v-col><div class="font-bold">{{ item.message.title }}</div></v-col>
                                                <v-col align="end">Reward Amount: {{ item.perPerson }} {{ item.asaName }}</v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <div class="text-caption font-weight-bold">Redirected Link</div>

                                                    <v-chip small :color="GetColor(item.type)" label>{{ item.type }}</v-chip> URL: {{ item.url }}
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="item.type=='GET'">
                                                <v-col>
                                                    <div class="text-caption font-weight-bold">Replace <span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span> with the hashes of your email addresses</div>
                                                    <pre>https://links.hokr.workers.dev/x/{{ item.id }}/<span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span></pre>
                                                </v-col>    
                                            </v-row>
                                            <v-row v-if="item.type=='POST'">
                                                <v-col>
                                                    <div class="text-caption font-weight-bold">Replace <span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span> with the hashes of your email addresses</div>
                                                    <pre>https://links.hokr.workers.dev/x/{{ item.id }}</pre>
                                                    
                                                        
<pre style="background-color:#dddddd; border: 1px solid black; padding:5px; margin-top:15px;">{ 
    "email":"<span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span>"
}</pre><span>JSON payload</span>

                                                    
                                                </v-col>    
                                            </v-row>

                                        </v-container>
                                    -->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>
                    
                </v-col>
            </v-row>
        </v-container>

        <v-btn
            color="primary"
            dark
            @click="showCreate=true"
            fab
            fixed
            bottom
            right
            x-large
            >
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-dialog :value="showCreate" width="800">
            <v-card>
                <v-card-text>
                    <PostCreate
                    :sessionGuid="sessionGuid"
                    :sessionTxID="sessionTxID"
                    :addr="addr"
                    @CloseCreate="CloseCreate"
                    >
                    </PostCreate>
                </v-card-text>
            </v-card>
        </v-dialog>

    </span>
</template>

<script>
import PostCreate  from './Post-Create.vue';
import PostItem from "./Post-Item.vue";
import { VueBus } from '../plugins/vuebus.js';
import * as moment from 'moment';
import axios from "axios";

export default {
    name: "MyPosts",
    components:{
        PostCreate,
        PostItem
    },
    props:["addr","sessionGuid","sessionTxID","action"],
    data: () => {
      return {
        showCreate: false,
        selectedTab: null,
        eventTotal: {
            open: 0,
            click: 0,
            dellivered: 0
        },
        posts: [],
        address: null,
        note: "",
        notes: null,
        brand: null,
        path: "https://worker.hokr.workers.dev/api/",
      }
    },
    watch:{
        async addr(oldv, newv){
            if(oldv != newv){
                if(!this.addr){
                    VueBus.$emit("Nav","/brands")
                }

                this.LoadPosts();
                
            }
        }
    },
    async mounted(){
        VueBus.$on('PostDelete',this.PostDeleteComplete)

        this.LoadPosts();        
    },
    methods:{
        async LoadPosts(overwrite){
            this.mybrand = await this.$store.dispatch('fetchBrand', this.addr )
            this.posts = await this.$store.dispatch('fetchFeed', this.addr.toUpperCase(), overwrite )
        },
        CloseCreate(){
            this.LoadPosts(true)
            this.showCreate = false;
        },
        GetColor(type){
            if(type=="GET")
                return "green"
            else
                return "blue"
        },
        GetFromNow(dateStr){
            if(!dateStr)
                return "No date";

            return moment(dateStr).fromNow();
        },
        async DeleteItem(item){
            let url = "/user/WALLETID/post/" + item.id
            let params = {
            followeeID: item.sender
            }
            let method = "DELETE"
            let action = "delete this post"
            let message = "This action cannot be undone."
            let callback = "PostDelete"

            
            VueBus.$emit('ConfirmAction',action, method, url, params, message, null, callback);
            
/*            
            setTimeout(async () =>  {
                this.posts = await this.$store.dispatch('fetchFeed', this.addr.toUpperCase(), true )
            }, 1000);
*/            
            //
        },
        ActivateItem(item){
            
            let url = "/user/WALLETID/post/" + item.id + "/toggle"
            let params = {
            
            }
            let method = "PUT"
            let action = "toggle visibility of this item"
            let message = "This action can be undone."
            let callback = "PostDelete"
            
            VueBus.$emit('ConfirmAction',action, method, url, params, message, null,  callback);
            
        },
        DeactivateItem(item){
            
            let url = "/user/WALLETID/post/" + item.id + "/toggle"
            let params = {
            
            }
            let method = "PUT"
            let action = "toggle visibility of this item"
            let message = "This action can be undone."
            let callback = "PostDelete"
            
            VueBus.$emit('ConfirmAction',action, method, url, params, message, null, callback);
            
        },
        PostDeleteComplete(){
            this.LoadPosts(true);
        },
        async ExpandPanel(item){
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                //'view': "getfungibles",
                'Accept': 'application/json',
            }

            
            let url = "https://worker.hokr.workers.dev/api/user/" + item.sender + "/post/" + item.id + "/tracking";
            var me = this;            
            return await axios.get(url, { headers: headers} )
              .then(async function (response) {
                console.log(response)
                me.eventTotal = response.data;
            })
        },
   

    }
}
</script>