<template>
  <v-card class="fill-height" >
    <v-system-bar color="#cccccc" height="10"></v-system-bar>
      <v-container v-if="ShowAuthed()" style="width:100%; margin-top:0px;" >
        <v-row>
          <v-col class="pa-0 v-col-12" cols="12">
            <div style="background-color:#5C6BC0; padding-left:10px; padding-top:12px;font-family:Lobster; font-size:24px; color:#ffffff; padding-bottom:10px; padding-right:30px;">Brands you may like</div>

            
            <v-sheet elevation="0" style="height:170px; width:100%;" class="overflow-hidden">
            <v-container style="height:170px; ">
              <v-row>
                <v-col v-for="brand in similarBrands" style="text-align:center; height:170px; "  @click="LoadItem(brand)" :key="brand.brandID">
                  <v-avatar width="100" height="100" :title="addr" color="grey">
                    <v-img v-if="brand.logoCid!=''" :src="GetURL(brand.logoCid)"></v-img>
                    <div v-if="brand.logoCid==''">{{ brand.brandName }}</div>
                  </v-avatar>
                  <div style="width:100%;text-align:center; margin-top:8px;" class="text-h6">{{ brand.brandName }}</div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
        </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            
            <div style="background-color:#5C6BC0; margin-top:20px; padding-left:10px; padding-top:12px; font-family:Lobster;font-size:24px;color:#ffffff; padding-bottom:10px;">Your feed</div>
            <PostItem
              showIcon="true"
              :addr="addr"
              :sessionGuid="sessionGuid"
              :sessionTxID="sessionTxID"
              style="background-color:#fff;"
              v-for="item in posts.slice().reverse()"
              v-bind:key="item.id"
              :postItem="item"
              :brand="brand"
              ></PostItem>

              <BrandRandom v-if="posts.length==0"></BrandRandom>

          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col>
            <WelcomeBanner></WelcomeBanner>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="border: 1px solid black;">
            <BrandRandom></BrandRandom>
          </v-col>
        </v-row>
      </v-container>

</v-card>

 
</template>

<script>
import axios from "axios";
import { VueBus } from '../plugins/vuebus.js';
import PostItem from "./Post-Item.vue";
import BrandRandom from "./Brand-Random.vue";
import WelcomeBanner from "./Welcome-Banner.vue";

export default {
    name: "Brand-Group",
    components:{
      
        PostItem,
        BrandRandom,
        WelcomeBanner,
        //AddressBar,
    },
    props:["addr","sessionGuid","sessionTxID"],
    data: () => {
      return {
        slugs: {},
        brand: {},
        similarBrands: [],
        rando: {},
        //post: {},
        pageUrl: location.href,
        dialShare: false,
        following: false,
        post: {
          message:{
            type:"default"
          }
        },
        walletType: "",
        posts:[],
        path: "https://worker.hokr.workers.dev/api/",
        
      }
    },
    async mounted(){
      if(this.addr){
        this.LoadBrands();
        this.LoadPosts();
      } //else {
        //this.LoadRandom();
      //}
      VueBus.$on('UpdatePosts',this.LoadPosts)
      console.log('mounted')
    },
    watch:{
      addr: function(newAddr, oldAddr) {

        
        
        if(newAddr != oldAddr){
          
          this.LoadBrands();
          this.LoadPosts();
        }
      }
    },
    methods:{
      GetURL(cid){
        let url = "https://worker.hokr.workers.dev/api/brandimages/"+ cid
        return url;
      },
      LoadItem(item){
        this.$router.push("/brands/" + item.slug)
      },
      async LoadBrands(){
        //await this.$nextTick()

            //this.GetNotifications();
            let url = this.path + "user/"+ this.addr +"/similar"
            let me = this;
            console.log(url)

            axios.get(url, {} )
            .then(async function (response) {
              me.similarBrands = response.data
            })
            .catch(function(error){
              console.log(error)
            })
     },
      ShowAuthed(){
        if(this.addr)
          return true;
        else
          return false;
      },
      async LoadPosts(){
        var id = this.addr

        let feedType = "myfeed"
        let params = {
          "actionUserID": this.addr
        }
        let headers = {}

        var me = this;
        let url = this.path + "user/" + id + "/" + feedType

        this.postData(url, "POST", params, headers)
        .then(async function (response) {
          console.log(response);
          me.posts = response;
        })
        .catch(function(error){
          console.log("Error on request")
          console.log(error)
        })
      },
      async postData(url = "", method="GET", data = {}) {
        var options = {
          method: method, // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        }

        if(method == "GET") delete options.body

        // Default options are marked with *
        const response = await fetch(url, options);
        


        return response.json(); // parses JSON response into native JavaScript objects
      } 

    }
}

</script>

<style>


</style>