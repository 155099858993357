<template>
    <v-container class="fill-height" color="purple" style="margin-top:45px;">
        <v-row  class="fill-height">
            <v-col  class="fill-height">

        <v-card fluid class="fill-height">
            <v-card-title>Wallet Type</v-card-title>
            <v-card-text style="background-color:white;"  class="fill-height">
                <div class="text-body2">The basic user type is "User". To follow more than two brands, you will need to upgrade your account to "Pro". To operate as a brand, you will need to select "Brand".</div>

                <v-radio-group v-model="userType">

                    <v-radio label="User" value="user" ></v-radio>
                    <v-sheet color="grey lighten-3 pa-3 mb-4">This is the basic user type.  A basic user can follow two brands.</v-sheet>
                    
                    <v-radio label="Pro" value="pro" ></v-radio>
                    <v-sheet color="grey lighten-3 pa-3 mb-4">A pro user requires a one-time fee of $2 which allows you to follow an unlimited brands</v-sheet>

                    <v-radio label="Brand" value="brand" ></v-radio>
                    <v-sheet color="grey lighten-3 pa-3 mb-4" >A Brand requires a special agreement with Rewardiply. For more information, contact sales@rewardiply.com</v-sheet>
<!--
                    <v-radio label="Admin" value="admin" ></v-radio>
                    <v-sheet color="grey lighten-3 pa-3 mb-4" >Don't pick this</v-sheet>
-->                    

                </v-radio-group>                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="Save">Save</v-btn>
            </v-card-actions>
        </v-card>

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import axios from "axios";
import { VueBus } from '../plugins/vuebus.js';



export default {
    name: "WalletType",
    props:["addr","sessionGuid","sessionTxID","action"],
    data: () => {
      return {
        userType: "",
      }
    },
    watch:{
        addr(oldv, newv){
            if(oldv != newv){
                if(!this.addr){
                    VueBus.$emit("Nav","/brands")
                }
                this.LoadBrand();
            }
        }
    },
    async mounted(){
        await this.$nextTick()
        
        if(!this.addr){
            VueBus.$emit("Nav","/brands")
        } else {
            this.LoadBrand();
        }
    },
    methods:{
        async LoadBrand(){
            let brand = await this.$store.dispatch('fetchBrand', this.addr.toUpperCase() )
            console.log(brand)
            this.userType = brand.userType;
        },
        async Save(){
            var message_payload = {
                userType: this.userType,
            }
            //var me = this;
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
            }

            var url = "https://worker.hokr.workers.dev/api/user/"+ this.addr +"/usertype"

            axios.post( url,message_payload, { headers: headers} )
                .then(async function (response) {
                if(response.data == "OK"){
                    
                    //check for tokens, if no? nav to create
                    let tokens = await this.$store.dispatch('fetchTokens', this.addr );

                    if(tokens.length == 0){
                        VueBus.$emit("Nav", "/brandsonboarding" )
                    }

                    
                }  else {
                    //me.$emit("save", false, "Something went wrong")
                }
            })
        } 
    }
}
</script>