<template>
  <v-sheet>
    <v-card>
      <v-card-text>
        <div class="text-body-1">
          
        </div>
        <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12" >
              <v-text-field
                v-model="username"
                label="Username" 
                @keydown="CheckEnter"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" >
              <v-text-field
                v-model="password"
                label="Password" 
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                @keydown="CheckEnter"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-container>
      </v-form>
        
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        text
        color="teal accent-4"
        @click="SignIn"
        >Sign In</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay
          :absolute="absolute"
          :value="overlay"
        >

        <v-card style="background-color:#666666; width:600px; height:400px;" color="#666666">
          <v-card-text>
        <div id="loop" class="center"></div>
        <div id="bike-wrapper" class="center">
          <div id="bike" class="centerBike"></div>
        </div>

        <div style="color:#FF8E00; margin-top:70px; font-size:48px; text-align:center;font-family:Lobster">
          Workin' on it.
        </div>
      </v-card-text>
      </v-card>


        
        </v-overlay>
  </v-sheet>
</template>

<script>
import axios from "axios";
//import store from "../plugins/vuex";
import { VueBus } from '../plugins/vuebus.js';



export default {
  props: ["addr","sessionGuid","sessionTxID"],
  components:{
    
  },
  data: () => {
    return {
        username:"",
        password:"",
        show1: false,
        overlay: false,
        absolute: false
    }
  },
  async mounted(){
  }, 
  methods: {

    SignIn(){
        this.overlay=true;

        console.log("Signing In")
        var headers = {
            action: "auth",
        }

        var message_payload = {
            username: this.username,
            password: this.password,
        }
        var me = this;

        let url =  "https://worker.hokr.workers.dev/api/auth";
        
        axios.defaults.withCredentials = true;
        axios.post( url, message_payload, { headers: headers} )
        .then(async function (response) {
            if(!response.data.addr){
              VueBus.$emit('ShowNotice', "Invalid credentials, let's try again", "red");
              me.overlay=false;

              return;
            }
          
            me.overlay = false;
            let d = response.data

            axios.defaults.withCredentials = false;
            VueBus.$emit("AuthComplete", d)
        })
        
        /*
        axios.post( "/.netlify/functions/Auth",message_payload, { headers: headers} )
        .then(async function (response) {
            if(!response.data.addr){
              VueBus.$emit('ShowNotice', "Invalid credentials, let's try again", "red");
              me.overlay=false;

              return;
            }
          
            me.overlay = false;
            let d = response.data


            VueBus.$emit("AuthComplete", d)
        })
        */
            
    },
    CheckEnter(evt){
      if (evt.keyCode === 13 && this.username != "" && this.password != "") 
      {
        this.SignIn();  
      }
    }    
  }
};
</script>

<style>
.centerBike {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 26px;
  margin-left: -12px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

#loop {
  height: 100px;
  width: 100px;
  border: #24C4EB solid 4px;
  border-radius: 200px;
}

#loop:before {
  background: linear-gradient(to left, rgba(187, 95, 39, 0) 0%, rgba(36, 196, 235, 1) 30%, rgba(36, 196, 235, 1) 70%, rgba(187, 95, 39, 0) 100%);
  content: "";
  display: block;
  height: 4px;
  left: -100px;
  position: relative;
  top: 94px;
  width: 300px;
}

#bike-wrapper {
  height: 108px;
  width: 108px;
  animation: drive 3s linear infinite;
}

#bike {
  height: 24px;
  width: 25px;
  background-image: url("../squatch.png");
}

@keyframes drive {
  0% {
    margin-left: -364px;
    opacity: 0;
  }
  33.33% {
    transform: rotate(0deg);
    margin-left: -50px;
    opacity: 1;
  }
  66.66% {
    transform: rotate(-360deg);
    margin-left: -50px;
    opacity: 1;
  }
  100% {
    margin-left: 264px;
    transform: rotate(-360deg);
    opacity: 0;
  }
}
</style>
