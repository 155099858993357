<template>
<div>
    <v-app-bar app elevate-on-scroll color="orange lighten-3" >
      <v-btn icon>
        <v-icon @click="drawer=true">mdi-menu</v-icon>
      </v-btn>

      <v-app-bar-title><a target="_blank" style="font-family:Poppins;text-decoration:none; color:#999999; font-weight:bold;"><img src="../../src/assets/rewardiply-logo.png"></a></v-app-bar-title> 
      <v-spacer></v-spacer>
      
      <template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          grey
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-wallet-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-wallet-outline</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>My Reward Tokens</v-list-item-title>
              
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <LoyaltyTokens
              :sessionGuid="sessionGuid"
              :sessionTxID="sessionTxID"
              :addr="addr"
          ></LoyaltyTokens> 
          
      </v-card>
    </v-menu>
  </div>
</template>
    <v-btn icon 
      v-show="searchBoxClosed==true"
    >
        <v-icon @click="ShowFT()">mdi-magnify</v-icon>
      </v-btn>

    <v-autocomplete
        v-show="searchBoxClosed==false"
        ref="searchBox"
        dense
        filled
        rounded
        @click:clear="ClearSearch"
        clearable
        placeholder="Search"
        prepend-inner-icon="mdi-magnify"
        class="pt-6 shrink expanding-search"
        v-model="model"
        :items="entries"
        :loading="isLoading"
        :search-input.sync="search"
        @change="PickItem"
        hide-no-data
        hide-selected
        item-text="name"
        item-value="brandID"
        return-object
        @focus="ShowFT()"
        @blur="HideFT()"
        :class="{ closed: searchBoxClosed && !searchText }"
    ></v-autocomplete>

    <!-- v-text-field 
      v-model.trim="searchText" 
      dense 
      filled 
      rounded 
      clearable 
      placeholder="Search" 
      prepend-inner-icon="mdi-magnify" 
      class="pt-6 shrink expanding-search" 
       @keyup.enter="onSearch"></v-text-field -->

      <v-btn icon>
        <v-icon @click="GetAuth">{{ GetAuthLogin() }}</v-icon>
      </v-btn>
 
    </v-app-bar>
    
    <v-navigation-drawer
            v-model="drawer"
            temporary
            absolute
            app
            style="position:fixed; top:0; left:0; overflow-y:scroll;"
            >

      <div style="text-align:center; background-color:#333333; color:#eeeeee; cursor:pointer" @click="LoadSelf()">
          <v-avatar width="100" height="100" :title="addr" style="border:1px solid white;">
            <v-img v-if="myBrand.logoCid!=''" :src="GetURL(myBrand.logoCid)"></v-img>
            <div v-if="myBrand.logoCid==''">{{ myBrand.brandName }}</div>
          </v-avatar><br/>

        {{ myBrand.brandName }}
      </div>
      <div class="text-center text-caption">
        Followers: {{ follows.followers }}
        Following: {{ follows.following }}
      </div>

      <v-divider></v-divider>
      <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title  class="font-weight-bold" style="cursor:pointer;" @click="Nav('brands')">Home</v-list-item-title>
      </v-list-item>
      <div v-if="addr != ''">

        <v-list-group
          value="1"
          prepend-icon="mdi-piggy-bank-outline"
        >
          <template v-slot:activator>
            <v-list-item-title class="font-weight-bold">Brands I follow</v-list-item-title>
          </template>


          <v-list-item
              v-if="myBrands.length == 0"
             link
            >
              <v-list-item-title  class="font-weight-bold" v-html="GetItem({brandName:'Follow some brands'})"></v-list-item-title>

            </v-list-item>


            <v-list-item
              v-for="item in myBrands"
              :key="item.senderID"
              link
            >
              <v-list-item-title v-html="GetItem(item)" @click="Nav(item)"></v-list-item-title>

            </v-list-item>
            
        </v-list-group>


        <v-list-group
          value=""
          prepend-icon="mdi-cogs"
        >
          <template v-slot:activator>
            <v-list-item-title  class="font-weight-bold">My Brand</v-list-item-title>
          </template>

          <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('settings')">
            <blockquote style='margin-left:50px;'>- Details</blockquote>
          </v-list-item-title>
        </v-list-item>


          <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('posts')">
            <blockquote style='margin-left:50px;'>- My Posts</blockquote>
          </v-list-item-title>
        </v-list-item>


        <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('storeitems')">
            <blockquote style='margin-left:50px;'>- My Store Items</blockquote>
          </v-list-item-title>
        </v-list-item>


        <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('links')">
            <blockquote style='margin-left:50px;'>- Links/API</blockquote>
          </v-list-item-title>
        </v-list-item>




        <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('loyalty')">
            <blockquote style='margin-left:50px;'>- Loyalty Points</blockquote>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('sales')">
            <blockquote style='margin-left:50px;'>- Sales</blockquote>
          </v-list-item-title>
        </v-list-item>



      </v-list-group>


      <v-list-group
          value=""
          prepend-icon="mdi-badge-account-outline"
        >
        <template v-slot:activator>
            <v-list-item-title  class="font-weight-bold">My Account</v-list-item-title>
          </template>



        <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('accountType')">
            <blockquote style='margin-left:50px;'>- Account Type</blockquote>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('notifications')">
            <blockquote style='margin-left:50px;'>- Email/SMS</blockquote>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title style="cursor:pointer;" @click="Nav('purchases')">
            <blockquote style='margin-left:50px;'>- Purchases</blockquote>
          </v-list-item-title>
        </v-list-item>

      </v-list-group>

      </div>
      <div v-else>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title style="cursor:pointer;" @click="Nav('create')">Create Account/Sign in</v-list-item-title>
        </v-list-item>


      </div>
    </v-list>


            <!--v-list
        nav
        dense
      >


      
        <v-list-item-group

          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title @click="Nav('brands')">Home</v-list-item-title>
          </v-list-item>

          <v-list-item>

            <v-list-item-title>My Brands</v-list-item-title>
          
            <v-list-group >
              <template v-slot:activator>
                <v-list-item
                  v-for="item in myBrands"
                  v-bind:key="item.senderID"
                  :title="item.brandName"
                >
                <v-list-item-content>
                  <v-list-item-title @click="Nav(item)" v-text="item.brandName"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              </template>

            </v-list-group>          
          </v-list-item>

        </v-list-item-group>
      </v-list-->

    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import LoyaltyTokens from "./Loyalty-Tokens.vue";

import { VueBus } from '../plugins/vuebus.js';

//import LoyaltyCreate from "./components/Loyalty-Create.vue";



export default {
    name: "AddressBar",
    components:{
        LoyaltyTokens,
    },

    props:["brand","addr","sessionGuid","sessionTxID"],
     data: () => {
      return {
        ShowWallet: false,
        drawer: false,
        follows: {
          followers:0,
          following:0
        },
        myBrands: [],
        myBrand: {},
        searchText: null,
        searchBoxClosed: true,
        descriptionLimit: 60,
        entries: [],
        isLoading: false,
        model: null,
        search: null,
        showSearch: false,
        fav: true,
        menu: false,
        message: false,
        hints: true,
      }
    },
    created(){
    },
    mounted(){
      this.LoadBrand();
      this.LoadNav();

    },
    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          const Description = entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + '...'
            : entry.Description

          return Object.assign({}, entry, { Description })
        })
      },
    },    
    watch:{
      addr(oldVal, newVal){
        if(oldVal != newVal){
          this.LoadBrand();
          this.LoadNav();
        }
      },
      search (val) {
        if(!val){
          this.entries = [];
          return;
        }

        // Items have already been loaded
        if (this.entries.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        fetch('https://worker.hokr.workers.dev/api/brand/search/'+val)
          .then(res => res.json())
          .then(res => {

            const { items, count } = res
            this.count = count
            this.entries = items
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },
    methods:{
      async LoadBrand(){
        let brand = await this.$store.dispatch('fetchBrand', this.addr.toUpperCase() )
        let follows = await this.$store.dispatch('fetchFollows', this.addr.toUpperCase(), true )

        console.log("MEOW")
        console.log(follows)

        this.follows = follows;
        this.myBrand = brand;
      },
      initSearch(){
        this.showSearch = true;
      },
      async LoadNav(){
        var me = this;
        var headers = {};

        axios.get("https://worker.hokr.workers.dev/api/user/" + this.addr + "/mybrands", { headers: headers} )
          .then(async function (response) {
            //store in vuex and check when new brand loads.
            me.$store.commit("SET_FOLLOWEDBRANDS",  response.data);
            me.myBrands = [];

            for(var item in response.data){
              me.myBrands.push( await me.$store.dispatch('fetchBrand', item.toUpperCase() ))
            }
            
            
            //me.myBrands = response.data;
            //console.log(me.myBrands)
        })

      },
      GetAuth(){
        if(this.addr)
          VueBus.$emit('Logout');
        else{
          let url = "";
          let params = {}
          let method = ""
          let action = ""

          VueBus.$emit('MembershipGate',action, method, url, params);
        }
      },
      GetAuthLogin(){
        if(this.addr!="")
          return "mdi-logout";
        else
          return "mdi-account";
      },

      GetTwitter(twitter){
        return "https://twitter.com/" + twitter
      },
      GetLinkedin(linkedin){
        return linkedin
      },
      async Nav(page){
        
        if(typeof page == "string")
        {
          switch(page){
            case "accountType":
              if(this.$router.currentRoute.path != "/account_type")
                  VueBus.$emit("Nav", "/account_type" )
              break;
            case "settings":
              if(this.$router.currentRoute.path != "/settings")
                  VueBus.$emit("Nav", "/settings" )
              break;
            case "loyalty":
              if(this.$router.currentRoute.path != "/loyalty")
                  VueBus.$emit("Nav", "/loyalty" )
              break;
            case "sales":
              if(this.$router.currentRoute.path != "/sales")
                  VueBus.$emit("Nav", "/sales" )
              break;
            case "links":
              if(this.$router.currentRoute.path != "/links")
                  VueBus.$emit("Nav", "/links" )
              break;
            case "posts":
              if(this.$router.currentRoute.path != "/posts")
                  VueBus.$emit("Nav", "/posts" )
              break;
            case "storeitems":
              if(this.$router.currentRoute.path != "/storeitems")
                  VueBus.$emit("Nav", "/storeitems" )
              break;

            case "purchases":
              if(this.$router.currentRoute.path != "/purchases")
                  VueBus.$emit("Nav", "/purchases" )
              break;

              case "notifications":
              if(this.$router.currentRoute.path != "/notifications")
                VueBus.$emit("Nav", "/notifications" )
              break;
            case "brands":
              if(this.$router.currentRoute.path != "/brands")
                VueBus.$emit("Nav", "/brands" )
              break;
            case "create":
              var action, method, url, finish = "";
              var params = {}
              VueBus.$emit('MembershipGate',action, method, url, params, finish);
              break;

            default:
              if(this.$router.currentRoute.path != page)
              VueBus.$emit("Nav", page )
          }
        }
        else{
          if(this.$router.currentRoute.path != "/brands/" + page.slug){
//            console.log("NEW SLUG: " + page.slug)
//            console.log(this.$router)

            VueBus.$emit("Nav", "/brands/" + page.slug)
//            await this.$router.push({ path: "/brands/" + page.slug });
          }
        }
      },
      GetItem(item){
        return "<blockquote style='margin-left:50px;'>- " + item.brandName + "</blockquote>"
      },
      GetURL(cid){
        let url = "https://worker.hokr.workers.dev/api/brandimages/"+ cid
        return url;
      },
      LoadSelf(){
        let slug = this.myBrand.slug
        if(this.$route.path != "/brands/" + slug)
        {
          this.$router.push("/brands/" + slug)
        }
      },
      async PickItem(item){
        let brand = await this.$store.dispatch('fetchBrand', item.brandID )
        let path = "/brands/" + brand.slug;

        this.Nav(path)
        this.entries = []
      },
      ClearSearch(){
        this.entries = []
      },
      HideFT(){
        this.searchBoxClosed = true
        //this.$refs.searchBox.$refs.input.style.width="100%"
        //this.$refs.searchBox.$refs.input.focus();
        console.log("hide")
        console.log(this.$refs.searchBox.$refs.input.value);
        console.log(this.$refs.searchBox);
      },
      ShowFT(){
        this.searchBoxClosed = false
        //this.$refs.searchBox.$refs.input.style.width="10%"
        
        console.log("show")
        setTimeout(async () =>  {
          this.$refs.searchBox.$refs.input.focus();    
        }, 1000);

      }
    }
  }


</script>

<style>
.v-input__slot.expanding-search{
	transition: max-width 0.5s
}
.v-input__slot.closed{
		max-width: 40px
}

</style>