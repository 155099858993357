<template>
  <div>
    <div style="padding-left:10px; margin-top:12px;font-family:Lobster; font-size:24px; color:#373232; margin-bottom:10px;" @click="NavBrand">Meet a random brand</div>
    <div style="text-align:left; background-color:#333333; color:#eeeeee; cursor:pointer; margin-bottom: 0px;" @click="NavBrand">
          
      <v-img
        class="text-center"
        justify="center"
        style="position:relative; background-color:#dddddd"
        height="150"
        :src="GetURL(rando.brand.backgroundCid)" >
      </v-img>
      <v-avatar width="106" height="106" color="#777777"                             
              style="margin-top:-50px; margin-left:30px;position:absolute;">
          <v-avatar width="100" height="100" >
            <v-img :src="GetURL(rando.brand.logoCid)"></v-img>
          </v-avatar>
        </v-avatar>

    </div>

    
    <PostItem
        
        showIcon="false"
        style="background-color:#fff;padding-top:60px;"
        :postItem="rando.post"
        :brand="rando.brand"
      ></PostItem>


  </div>
</template>

<script>
import { VueBus } from '../plugins/vuebus.js';
import PostItem from "./Post-Item.vue";

export default {
    name: "Brand-Random",
    components: {
      PostItem
    },
    props:["addr","sessionGuid","sessionTxID"],
    data: () => {
      return {
        rando: {
          brand:{
            backgroundCid: "",
            logoCid: "",
          }
        },
        //post: {},
        path: "https://worker.hokr.workers.dev/api/",        
      }
    },
    async mounted(){
      this.LoadRandom();
    },
    methods:{
      GetURL(cid){
        let url = "https://worker.hokr.workers.dev/api/brandimages/"+ cid
        return url;
      },
      NavBrand(){
        let path = "/brands/" + this.rando.brand.slug
        VueBus.$emit("Nav", path )
      },
      LoadRandom(){
      let url = this.path + "brand/random"
      var params, headers = null;
      var me = this;
      this.postData(url, "GET", params, headers)
        .then(async function (response) {
          me.rando = response;
        })
        .catch(function(error){
          console.log("Error on request")
          console.log(error)
        })
     },
      async postData(url = "", method="GET", data = {}) {
        var options = {
          method: method, // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        }

        if(method == "GET") delete options.body

        // Default options are marked with *
        const response = await fetch(url, options);
        return response.json(); // parses JSON response into native JavaScript objects
      } 

    }
}

</script>

<style>


</style>