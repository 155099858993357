<template>
    <v-container >
        <v-row>
            <v-col>
                <v-card color="white">
                <v-card-title>Hold up</v-card-title>
                <v-card-subtitle>We need your address</v-card-subtitle>
                <v-card-text>
                    <v-form>
                        <v-container>
                            <v-row >
                                <v-col cols="12" class="col-12">
                                    <v-text-field
                                        v-model="address.name"
                                        label="Name"
                                        :rules="[rules.required]"
                                        >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row >
                                <v-col cols="12" class="col-12">
                                    <v-text-field
                                        v-model="address.address1"
                                        label="Address"
                                        :rules="[rules.required]"
                                        >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row >
                                <v-col cols="12" class="col-12">
                                    <v-text-field
                                        v-model="address.address2"
                                        label="Address 2"
                                        >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="col-5">
                                    <v-text-field
                                        v-model="address.city"
                                        label="City"
                                        :rules="[rules.required]"
                                        >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="5" class="col-5">
                                    <v-text-field
                                        v-model="address.state"
                                        label="State"
                                        :rules="[rules.required]"
                                        >
                                    </v-text-field>
                                    </v-col>
                                    <v-col  cols="2" class="col-2">
                                    <v-text-field
                                        v-model="address.zip"
                                        label="Postal code"
                                        :rules="[rules.required]"
                                        >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-form>



                    

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="primary" @click="SaveAdddress">Save</v-btn>
                </v-card-actions>
                </v-card>

            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import axios from "axios";
import { VueBus } from '../plugins/vuebus.js';



export default {
  props:["addr","sessionGuid","sessionTxID","pushEnabled",],
  data: () => {
    return {
        address: {},
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          mobile: value => {
            const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
            return pattern.test(value) || 'Invalid mobile.'
          },
        },
    }
  },
  async mounted(){
    this.GetNotifications();
  },
  watch:{
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
        if(!this.addr){
            VueBus.$emit("Nav","/brands")
        }
        
        if(newAddr != oldAddr){
            this.GetNotifications();
        }
    },    
  },
  methods: {
    GetHeaders(view){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view':view
          }
          return headers;
    },
    SaveAdddress(){
        this.address.walletID = this.addr;
        console.log(this.address)
        var me = this;
        axios.post("/.netlify/functions/Config", this.address, { headers: this.GetHeaders("saveaddress")} )
            .then(async function (response) {
            console.log("SaveAddress: " + response.data)

            me.$emit("addressDone")
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    async GetNotifications(){
        this.address = await this.$store.dispatch('fetchAddress', this.addr.toUpperCase() )

        //hide it and continue if you can
        if(this.address.name != ""){
            this.$emit("addressDone")
        }

    }
  }
}
</script>

<style></style>