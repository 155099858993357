<template>
    <v-card class="fill-height pa-0 ma-0" >

      <v-app-bar dense color="#3B3059" height="30" >
        <span style="margin-top:20px;">
          <v-checkbox
          v-model="following"
          
          @change="ToggleFollow"
          flat
          dark
          label="Following"
          ></v-checkbox>
        </span>
        
        <v-spacer></v-spacer>

        <v-btn v-if="brand.linkedIn" :href="GetLinkedin(brand.linkedIn)" icon >
          <v-icon color="white" medium>mdi-linkedin</v-icon>
        </v-btn>

        <v-btn v-if="brand.twitter" :href="GetTwitter(brand.twitter)" icon  >
          <v-icon color="white" medium>mdi-twitter</v-icon>
        </v-btn>

        <v-btn v-if="brand.emailSignup" :href="brand.emailSignup" icon  >
          <v-icon color="white" medium>mdi-email</v-icon>
        </v-btn>

      </v-app-bar>
    
    <v-container style="background-color:#fefefe;" class="fill-height" max-width="1000" >
      <v-row>
        <v-col class="pa-0 ma-0 text-left">
          <v-img
            class="text-center"
            justify="center"
            style="position:relative; background-color:#dddddd"
            height="150"
            :src="GetURL(brand.backgroundCid)" >
          </v-img>
            <v-avatar width="106" height="106" color="#777777"                             
              style="margin-top:-50px; margin-left:30px;position:absolute;"
            >
          <v-avatar width="100" height="100" >
            <v-img :src="GetURL(brand.logoCid)" @click="location.href='brand.url'"></v-img>
          </v-avatar>
        </v-avatar>

        </v-col>
      </v-row>
      <!-- Provides the application the proper gutter -->
            <v-row>
              <v-col class="pa-0 ma-0">  
                <div style="font-family:Poppins; background-color:#ccc; padding:20px; font-style:italic; text-align:left;padding-left:200px;">
                  {{ brand.description }}
                </div>
              </v-col>
            </v-row>
            <v-row class="fill-height">
                <v-col class="pa-0 ma-0 fill-height" >
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-breadcrumbs
                            :items="breadCrumbs"
                            divider="|"
                          >
                          <template v-slot:item="{ item }">
                            <v-breadcrumbs-item style="cursor:pointer;" @click="GetLinkPath(item.text)">
                              {{GetLinkName(item.text)}}
                            </v-breadcrumbs-item>
                          </template>

                          </v-breadcrumbs>
                      </v-col>
                      <v-col align="end">
                        <v-btn @click="GoStore" style="margin-top:10px;">
                          <v-icon>mdi-storefront</v-icon>
                          Store
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>


                  <div class="fill-height">
                    <PostItem
                    showIcon="false"
                    :addr="addr"
                    :sessionGuid="sessionGuid"
                    :sessionTxID="sessionTxID"
                    style="background-color:#fff;"
                    v-for="item in posts.slice().reverse()"
                    v-bind:key="item.id"
                    :postItem="item"
                    :brand="brand"
                    ></PostItem>
                  </div>
                </v-col>
            </v-row>
        </v-container>

        

    </v-card>

</template>

<script>
import PostItem from "./Post-Item.vue";
//import AddressBar from "./Address-Bar.vue";
import { VueBus } from '../plugins/vuebus.js';
//import axios from "axios";


export default {
    name: "BrandView",
    components:{
        PostItem,
        //AddressBar,
    },
    props:["addr","sessionGuid","sessionTxID"],
    data: () => {
      return {
        slugs: {},
        brand: {},
        posts: [],
        following: false,
        walletType: "",
        path: "https://worker.hokr.workers.dev/api/",
        postCounter: 0,
        breadCrumbs: [
        {
          text: 'home',
          disabled: false,
          href: '/',
        },
        {
          text: 'brand',
          disabled: false,
          href: '/1',
        }
      ],        
      }
    },
    async mounted(){
      this.LoadBrand(this.$route.params.brandID)

      VueBus.$on('UpdatePosts',this.UpdatePosts)

    },
    watch: {
      '$route.params.brandID'(oldv, newv){
        if(oldv != newv){
          this.LoadBrand(this.$route.params.brandID)
        }
      },
      addr(oldVal, newVal){
        if(oldVal != newVal){

          if(this.brand){
            this.CheckFollow(this.brand.senderID);
          }
        }
      },

    },
    methods:{
      async LoadBrand(brandID){
        var firstLetter = brandID.charAt(0)
      
        var me = this;
        await fetch("https://worker.hokr.workers.dev/api/slugs/" + firstLetter )
            .then(r => r.json())
            .then(json => {
            me.slugs=json;
        })
        
        var id = this.slugs[this.$route.params.brandID].toUpperCase()

        this.brand = await this.$store.dispatch('fetchBrand', id );

        this.CheckFollow(id);
        this.UpdatePosts(id);
    },
    UpdatePosts(id){
      console.log(id)
      //let id = this.slugs[this.$route.params.brandID].toUpperCase()

      let feedType = "feed"
      let params = {}
      let headers = {}

      if(this.addr){
        feedType = "brandfeed"
 
        params = {
          actionUserID: this.addr
        }
      }

      let url = this.path + "user/" + id + "/" + feedType;
      var me = this;
      
      this.postData(url, "POST", params, headers)
        .then(async function (response) {
          me.posts = response;
        })
        .catch(function(error){
          console.log("Error on request")
          console.log(error)
        })
    },
    async CheckFollow(id){
      this.following = await this.$store.dispatch('fetchFollow', id )
    },
    ToggleFollow(){
      let url = "/user/WALLETID/toggle"
      let params = {
        followeeID: this.brand.senderID
      }
      let method = "POST"
      let action = "follow that brand"
      
      VueBus.$emit('MembershipGate',action, method, url, params);
    },
    GetURL(cid){
        let url = "https://worker.hokr.workers.dev/api/brandimages/"+ cid
        return url;
    },
    GetTwitter(twitter){
      return "https://twitter.com/" + twitter
    },
    GetLinkedin(linkedin){
      return linkedin
    },
    parseBool(val){
      (/true/).test(val)
    },

    ListMessages(){

    },
    GetLinkName(path){
      switch(path){
        case "home":
          return "Home"
        case "brand":
          return this.brand.brandName
          //return this.$route.params.brandID
        case "item":
        if(this.post.message.header == "")
          return "Item"
        else  
          return this.post.message.header
      }
    },
    GetLinkPath(path){
      var retpath = ""
      switch(path){
        case "home":
          retpath = "/brands";
          break;
        case "brand":          
          retpath = "/brands/" + this.$route.params.brandID
          break;
        case "item":
          retpath =  "/brands/" + this.$route.params.brandID + "/post/" + this.$route.params.postID
          break;
      }

      VueBus.$emit("Nav", retpath);

      //return "{ params: { path: '"+ retpath +"',exact:true  }}"
      return retpath;
    },
    GoStore(){
      VueBus.$emit("Nav", this.$route.path + "/store");
    },
    async postData(url = "", method="GET", data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: method, // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
      }
  }
}

</script>

<style>
body {
  background-color:#fefefe;
  font-family:Poppins;
}
.v-card {
  background-color: #eee !important;
}

</style>