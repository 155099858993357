<template>
        <v-container>
          <v-row>
            <v-col cols="12"><v-text-field @blur="SetName" label="Name" v-model="name"></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><v-text-field @blur="SetUsername" label="Username" v-model="username"></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field 
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                @blur="SetPassword" 
                label="Password" 
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                v-model="password"></v-text-field></v-col>
          </v-row>

        </v-container>

</template>

<script>
//import axios from "axios"

export default {
  props:["addr","sessionGuid","sessionTxID","pushEnabled",],
  data: () => {
    return {
        name: "",
        username: "",
        password:"",
        showPassword: false,
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          mobile: value => {
            const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
            return pattern.test(value) || 'Invalid mobile.'
          },
        },
    }
  },
  methods: {
    GetHeaders(view){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view':view
          }
          return headers;
    },
    SetName(){
      this.$emit("SetName", this.name)
    },
    SetUsername(){
      this.$emit("SetUsername", this.username)
    },
    SetPassword(){
      this.$emit("SetPassword", this.password)
    }
  }
}
</script>