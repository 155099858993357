<template>
<div>
    <v-card>
      <v-app-bar dense color="#3B3059" height="30">

      <span style="margin-top:20px;">
          <v-checkbox
          v-model="following"
          :checked="following"
          @change="ToggleFollow"
          flat
          dark
          label="Following"
          ></v-checkbox>
        </span>

<v-spacer></v-spacer>

<v-btn  icon >
  
</v-btn>

<v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="white"
          dark
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-qrcode</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          QR Code
        </v-card-title>

        <v-card-text>
          <v-img :src="GetQR()"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<v-btn v-if="brand.linkedIn" :href="GetLinkedin(brand.linkedIn)" icon >
  <v-icon color="white" medium>mdi-linkedin</v-icon>
</v-btn>

<v-btn v-if="brand.twitter" :href="GetTwitter(brand.twitter)" icon  >
  <v-icon color="white" medium>mdi-twitter</v-icon>
</v-btn>

<v-btn v-if="brand.emailSignup" :href="brand.emailSignup" icon  >
  <v-icon color="white" medium>mdi-email</v-icon>
</v-btn>

</v-app-bar>


    <v-container style="background-color:#fefefe" max-width="1000">
      <v-row>
        <v-col class="pa-0 ma-0 text-left">
          <v-img
            class="text-center"
            justify="center"
            style="position:relative; background-color:#dddddd"
            height="150"
            :src="GetURL(brand.backgroundCid)" >
          </v-img>
            <v-avatar width="106" height="106" color="#777777"                             
              style="margin-top:-50px; margin-left:30px;position:absolute;"
>
          <v-avatar width="100" height="100" >
            <v-img :src="GetURL(brand.logoCid)"></v-img>
          </v-avatar>
        </v-avatar>

        </v-col>
      </v-row>
      <!-- Provides the application the proper gutter -->
            <v-row>
              <v-col class="pa-0 ma-0">  
                <div style="font-family:Poppins; background-color:#ccc; padding:20px; font-style:italic; text-align:left;padding-left:200px;">
                  {{ brand.description }}
                </div>
              </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-0 ma-0">
                  <v-breadcrumbs
                    :items="breadCrumbs"
                    divider="|"
                  >
                  <template v-slot:item="{ item }">
                    <v-breadcrumbs-item style="cursor:pointer;" @click="GetLinkPath(item.text)">
                      {{GetLinkName(item.text)}}
                    </v-breadcrumbs-item>
                  </template>

                  </v-breadcrumbs>


                  <PostItem
                    showIcon="false"
                    style="background-color:#fff;"
                    :postItem="post"
                    :brand="brand"
                    :addr="addr"
                    :sessionTxID="sessionTxID"
                    :sessionGuid="sessionGuid"
                    :walletType="walletType"
                    
                    ></PostItem>


                    <v-speed-dial  v-model="dialShare" fixed bottom right direction="top" open-on-hover>
                      <template v-slot:activator>
                        <v-btn fab bottom medium color="primary">
                          <v-icon v-if="dialShare">mdi-close</v-icon>
                          <v-icon v-else>mdi-share-variant</v-icon>
                        </v-btn>
                      </template>
                      <v-btn dark fab bottom color="blue darken-7" medium :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`" target="_blank">
                        <v-icon>mdi-linkedin</v-icon>
                      </v-btn>
                      <v-btn dark fab bottom color="blue" medium :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`" target="_blank">
                        <v-icon>mdi-facebook</v-icon>
                      </v-btn>
                      <v-btn dark fab bottom color="green" medium :href="`https://wa.me/?text=Checkout%20this%20page.%20${pageUrl}`" target="_blank">
                        <v-icon>mdi-whatsapp</v-icon>
                      </v-btn>
                      <v-btn dark fab bottom color="tertiary" medium :href="`mailto:?subject=Awesomeness!&amp;body=Checkout this page!<a href='${pageUrl}'>${pageUrl}</a>`" target="_blank">
                        <v-icon>mdi-email</v-icon>
                      </v-btn>

                    </v-speed-dial>
                    
                </v-col>
            </v-row>
        </v-container>
 

    
    </v-card>

</div>
</template>

<script>
import PostItem from "./Post-Item.vue";
import { VueBus } from '../plugins/vuebus.js';
import axios from "axios";


export default {
    name: "Brand-Item",
    components:{
        PostItem,

    },
    props:["addr","sessionGuid","sessionTxID"],
    data: () => {
      return {
        dialog: false,
        
        slugs: {},
        brand: {},
        //post: {},
        pageUrl: location.href,
        dialShare: false,
        following: false,
        post: {
          message:{
            type:"default"
          }
        },
        walletType: "",
        breadCrumbs: [
        {
          text: 'home',
          disabled: false,
          href: '/',
        },
        {
          text: 'brand',
          disabled: false,
          href: '/1',
        },
        {
          text: 'item',
          disabled: true,
          href: '/1/2',
        },
      ],
        
      }
    },
    async mounted(){
      await this.LoadBrand()
    },
    watch:{
      addr(oldVal, newVal){
        if(oldVal != newVal){
          if(this.brand){
            this.CheckFollow(this.brand.senderID);
          }
        }
      }
    },
    methods:{
      async LoadBrand(){
        console.log("LOAD BRAND NOW")
        var brandID = this.$route.params.brandID;
        var postID = this.$route.params.postID;

        var id = await this.$store.dispatch('fetchIdFromSlug', brandID )
    
        this.brand = await this.$store.dispatch('fetchBrand', id )
        this.post = await this.$store.dispatch('fetchItem', { brandID: id, postID: postID } )
        
        this.CheckFollow(id.toUpperCase());
/*
        var me = this;

        var firstLetter = brandID.charAt(0)
        
        await fetch("https://worker.hokr.workers.dev/api/slugs/" + firstLetter )
            .then(r => r.json())
            .then(json => {
            me.slugs=json;
        })
        
        var id = this.slugs[this.$route.params.brandID]
        this.brand = await this.$store.dispatch('fetchBrand', id );

        this.CheckFollow(id.toUpperCase());

        var url = "https://worker.hokr.workers.dev/api/user/"+ this.brand.senderID +"/post/" + postID  

        await fetch(url)
            .then(function(r){ console.log(r); return r.json()})
            .then(json => {
              me.post=json;
            })
            .catch(function(err){console.log(err)})

        

//        var postID = id.toUpperCase() + "-" + this.$route.params.postID
*/

    },
    ToggleFollow(){
      var data = {
            followerID: this.addr
          }

      var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
            } 
            
           //var headers = {};
          let brandID = this.brand.senderID


          axios.post("https://worker.hokr.workers.dev/api/user/" + brandID + "/toggle",data, { headers: headers} )
            .then(async function (response) {
              console.log(response.data)
            })
    },
    GetURL(cid){
        let url = "https://worker.hokr.workers.dev/api/brandimages/"+ cid
        return url;
      },
    async CheckFollow(id){
      this.following = await this.$store.dispatch('fetchFollow', id )
    },
    GetQR(){
      let url = "https://worker.hokr.workers.dev/api/brand/" + this.$route.params.brandID + "/qr"
      return url;
    },
/*
    CheckFollow(id){
      var me = this;
          var headers = {
                    'sessionGuID': this.sessionGuid,
                    'sessionTxID': this.sessionTxID,
                    'walletID': this.addr,
                } 
          var data = {
              followerID: id
            }

          axios.post("https://worker.hokr.workers.dev/api/user/" + this.addr + "/following",data, { headers: headers} )
            .then(async function (response) {
              me.following = response.data;
          })
          .catch(function(error){
            console.log("Error on request")
            console.log(error)
          })

    },
    */
    GetTwitter(twitter){
      return "https://twitter.com/" + twitter
    },
    GetLinkedin(linkedin){
      return linkedin
    },
    ListMessages(){

    },
    parseBool(val){
      (/true/).test(val)
    },
    GetLinkName(path){
      switch(path){
        case "home":
          return "Home"
        case "brand":
          return this.brand.brandName
          //return this.$route.params.brandID
        case "item":
        if(this.post.message.header == "")
          return "Item"
        else  
          return this.post.message.header
      }
    },
    GetLinkPath(path){
      var retpath = ""
      switch(path){
        case "home":
          retpath = "/brands";
          break;
        case "brand":          
          retpath = "/brands/" + this.$route.params.brandID
          break;
        case "item":
          retpath =  "/brands/" + this.$route.params.brandID + "/post/" + this.$route.params.postID
          break;
      }

      VueBus.$emit("Nav", retpath);

      //return "{ params: { path: '"+ retpath +"',exact:true  }}"
      return retpath;
    }

  }
}

</script>

<style>
body {
  background-color:#fefefe;
  font-family:Poppins;
}
.v-card {
  background-color: #eee !important;
}

</style>