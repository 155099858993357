<template>  
  <v-app>
      <router-view
          :addr="addr"
          :sessionGuid="sessionGuid"
          :sessionTxID="sessionTxID"
      ></router-view>

      <v-dialog v-model="memberGate" max-width="800">
        <AuthIt :action="action"
          :addr="addr"
          :sessionGuid="sessionGuid"
          :sessionTxID="sessionTxID"
          @AuthComplete="AuthComplete"
        ></AuthIt>
      </v-dialog>

      <v-dialog v-model="confirmAction" max-width="800">
        <v-overlay :value="confirmOverlay">
          <v-progress-circular
              :size="150"
              color="amber"
              indeterminate
            ></v-progress-circular>
          </v-overlay>

        <v-card>
          <v-card-title>Confirm you want to {{ action }}</v-card-title>
          <v-card-text>
            {{ confirmText }}


            <AddressSetup
            v-if="showAddress"
            :addr="addr"
            :sessionGuid="sessionGuid"
            :sessionTxID="sessionTxID"
            @addressDone="AddressDone"
            >
          </AddressSetup>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!allClear" @click="ConfirmTrue()">Yes</v-btn>
            <v-btn @click="confirmAction = false;">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-snackbar
        v-model="notice"
        :color="noticeColor"
        dismissible
        prominent
        timeout="4000"
        right
                    
        transition="scale-transition"
        align="right"
      >
        {{noticeText}}
      </v-snackbar>
  </v-app>
</template>

<script>
import { VueBus } from './plugins/vuebus.js';
import WalletBroker from "./plugins/wallets/WalletBroker.js";
import Cookies from 'js-cookie'
import AuthIt from './components/Auth-It.vue'
import AddressSetup from './components/Address-Setup.vue'
import axios from "axios";

//import WalletCreate from './components/Wallet-Create.vue'
//import SignIn from './components/Sign-In.vue'



export default {
  name: "Rewardiply-Home",
  components:{ AuthIt, AddressSetup },
  data: () => {
    return {
      stats: {
        m: 0,
        rt: 0,
        tr: 0,
      },
      notice: "",
      noticeColor: "",
      noticeText: "",
      memberGate: false,
      action: "",
      method: "",
      finish: "",
      finishID: "",
      params: {},
      url: "",
      addr:"",
      sessionTxID: "",
      sessionGuid: "",
      confirmText: "",
      confirmAction: false,
      confirmOverlay: false,
      includeAddress: false,
      allClear: true,
      showAddress: false,
      path: "https://worker.hokr.workers.dev/api",
    }
  },
  async mounted(){
    if( Cookies.get("sessionTxID") && Cookies.get("sessionGuid")) {
      this.walletType = Cookies.get("walletType");
      WalletBroker.reconnect(this);

      this.addr = Cookies.get("walletID");

      this.sessionTxID = Cookies.get("sessionTxID");
      this.sessionGuid = Cookies.get("sessionGuid");
      this.walletType = Cookies.get("walletType");

      this.$store.commit("SET_SESSION_GUID", Cookies.get("sessionGuid"));
      this.$store.commit("SET_SESSION_TXID", Cookies.get("sessionTxID"));
      this.$store.commit("SET_WALLETID", Cookies.get("walletID"));
    }

    VueBus.$on('ConfirmAction',this.ConfirmAction)
    VueBus.$on('MembershipGate',this.MembershipGate)
    VueBus.$on('Logout',this.Logout)
    VueBus.$on('Nav',this.Nav)
    VueBus.$on('ShowNotice',this.ShowNotice)
    VueBus.$on('AuthComplete',this.AuthComplete)


  }, 
  methods: {
    async ConfirmAction(action, method, url, params, message, include, finish, finishID){
      this.action = action;
      this.method = method;
      this.url = this.path + url;
      this.params = params;
      this.finish = finish;
      this.finishID = finishID;

      this.confirmText = message;

      //user not logged on, show wallet create
      this.confirmAction = true;

      switch(include){
        case "address":
          var address = await this.$store.dispatch('fetchAddress', this.addr.toUpperCase() )
 
          if(address.name == "")
          {
            this.allClear = false;
            this.showAddress = true;
          }
          break;
      }
      //this.CallEvent();
      
      /*
      if(finish)
        VueBus.$emit(finish);
      */
    },
    ConfirmTrue(){
      this.CallEvent();
    },
    CancelConfirm(){
      this.confirmAction = false;
    },
    MembershipGate(action, method, url, params, finish, finishID){

      this.action = action;
      this.method = method;
      this.url = this.path + url;
      this.params = params;
      this.finish = finish;
      this.finishID = finishID;

      //user not logged on, show wallet create
      if(this.addr == "" || this.action==""){
        this.memberGate = true;
      } else {

        this.CallEvent();        
      }

    },
    AuthComplete(d){
      this.addr = d.addr;
      this.sessionGuid = d.sessionGuid;  //stored on DO?
      this.sessionTxID = d.sessionTxID;  //stored on KV?

      Cookies.set("walletID", d.addr, {sameSite: 'None', secure: true, expires: 30});
      Cookies.set("walletType", "organic", {sameSite: 'None', secure: true, expires: 30});
      Cookies.set("sessionTxID", d.sessionTxID, {sameSite: 'None', secure: true, expires: 30});
      Cookies.set("sessionGuid", d.sessionGuid, {sameSite: 'None', secure: true, expires:30});

      this.$store.commit("SET_SESSION_GUID", d.sessionGuid);
      this.$store.commit("SET_SESSION_TXID", d.sessionTxID);
      this.$store.commit("SET_WALLETID", d.addr);
      this.$store.commit("SET_WALLETTYPE", "organic")


      //this.CallEvent();

      if(this.$route.path == "/login"){
        VueBus.$emit("Nav", "/brands")
      }

      this.memberGate = false;
    },
    CallEvent(){
      this.confirmOverlay = true;

      this.url = this.url.replace(/WALLETID/, this.addr)
      //this.url = "https://worker.hokr.workers.dev/api" + this.url.replace(/WALLETID/, this.addr)
      var me = this;

      axios({
        headers:{
          'sessionGuID': this.sessionGuid,
          'sessionTxID': this.sessionTxID,
          'walletID': this.addr
        },
        method: this.method,
        url: this.url,
        data: this.params
      })
      .then(async function (response) {
        if(response.data.paid){
          me.ShowNotice(response.data.message, "green") 
          
          if(this.finish){
            console.log("finish")
            VueBus.$emit(me.finish, me.finishID); 
          }

          me.confirmAction = false;  
          me.confirmOverlay = false;
        }
        else if(response.data.success){
          me.ShowNotice(response.data.message, "green")  

          if(me.finish){
            console.log("finish")
            VueBus.$emit(me.finish, me.finishID); 
          }

          me.confirmAction = false;  
          me.confirmOverlay = false;
        }
        else {
          me.confirmOverlay = false;

          var messageCheck = /balance.*?\)/
          var message = "";

          if(messageCheck.test(response.data.message)){
            message = "Sender has " + response.data.message.match(messageCheck)[0]
          } else {
            message = response.data.message
          }

          me.ShowNotice(message, "red") 
        }   
      })
      .catch(function (error) {
        me.confirmOverlay = false;  
        me.ShowNotice(error, "red")    
      });
    },
    Nav(path, message){
      console.log(path)
      console.log(this.$route.path)
      if(this.$route.path != path){
        var props = { path: path,  props: message }
        this.$router.push(props)//.catch(() => {});
      }
    },
    ListMessages(){

    },
    async Logout(){
      this.$store.commit("SET_SESSION_GUID", "");
      this.$store.commit("SET_SESSION_TXID", "");
      this.$store.commit("SET_WALLETID", "");
      this.$store.commit("SET_WALLETTYPE", "")

      this.addr = "";
      this.sessionTxID = "";
      this.sessionGuid = "";
      this.walletType = "";

      Cookies.set("walletType", "");
      Cookies.set("walletID", "");
      Cookies.set("sessionTxID", "");
      Cookies.set("sessionGuid", "");

    },
    ShowNotice(message, color){
      this.noticeText = message;
      this.noticeColor = color;
      
      this.notice = true;
    },
    AddressDone(){
      this.showAddress=false;
      this.allClear=true
    }
  }
}

</script>

<style>
 
</style>
