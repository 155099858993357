import { render, staticRenderFns } from "./Admin-Loyalty.vue?vue&type=template&id=493f73ed&"
import script from "./Admin-Loyalty.vue?vue&type=script&lang=js&"
export * from "./Admin-Loyalty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports