<template>
    <div>

    <v-container class="fill-height" color="purple">
        <v-row  class="fill-height">
            <v-col  class="fill-height">
                <v-card class="fill-height" style="background-color:white; margin-top:45px;">
                    <v-card-title>
                        My Store Items
                    </v-card-title>
                    <v-card-text>
                        <v-expansion-panels popout>
                            <v-expansion-panel @click="ExpandPanel(item)"
                                v-for="item in storeItems" :key="item.txID">
                                <v-expansion-panel-header><v-container>
                                    <v-row>
                                        <v-col>

                                            {{ item.title }}</v-col>
                                            
                                        <v-spacer></v-spacer>
                                        <v-col align="end">
                                            {{ getDateStr(item.createdDate) }}
                                        
                                        </v-col>
                                    </v-row>
                                </v-container> </v-expansion-panel-header>
                                <v-expansion-panel-content color="#dddddd">
                                    <v-toolbar floating>
                                        
                                        
                                        
                                        <v-btn icon @click="EditItem(item)"><v-icon>mdi-lead-pencil</v-icon></v-btn>
                                        <v-btn icon @click="DeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                                        <v-btn icon @click="ToggleItem(item)" v-if="item.status=='active'"><v-icon>mdi-eye-off</v-icon></v-btn>
                                        <v-btn icon @click="ToggleItem(item)" v-if="item.status=='inactive'"><v-icon>mdi-eye-outline</v-icon></v-btn>

                                    </v-toolbar>
                                    
                                    <v-container>
                                        <v-row>
                                            <v-col class="text-h5 font-weight-bold v-col-9">
                                                {{ item.title }}
                                            </v-col>
                                            <v-col class="v-col-3" align="end">
                                                {{ item.amount }} {{ item.asaName }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-img 
                                                    aspect-ratio="1"
                                                    max-width="100%"
                                                    :src="GetStoreURL(item.bgImage)"></v-img>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>{{ item.description }}</v-col>
                                        </v-row>
                                    </v-container>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>                    
                    </v-card-text>
                </v-card>
                
            </v-col>
        </v-row>
    </v-container>


            <v-btn
            color="primary"
            dark
            @click="showCreate=true"
            fab
            fixed
            bottom
            right
            x-large
            >
            <v-icon>mdi-plus</v-icon>
            </v-btn>

    <v-dialog :value="showCreate" >
            <CreateStoreItem
                :item="selectedItem"
                :sessionGuid="sessionGuid"
                :sessionTxID="sessionTxID"
                :addr="addr"
                @HideCreate="CloseCreate"
            >
            </CreateStoreItem>
        </v-dialog>

    </div>

</template>

<script>
import { VueBus } from '../plugins/vuebus.js';
import * as moment from 'moment'
import CreateStoreItem from "./Create-Store-Item.vue";

//import axios from "axios";



export default {
    name: "MyStoreItems",
    props:["addr","sessionGuid","sessionTxID","action"],
    components: { CreateStoreItem },
    data: () => {
      return {
        showCreate: false,
        selectedItem: null,
        storeItems: [],
        address: null,
        note: "",
        notes: null,
        path: "https://worker.hokr.workers.dev/api/",
      }
    },
    watch:{
        async addr(oldv, newv){
            if(oldv != newv){
                console.log("swtich")
                console.log(oldv)
                await this.LoadItems();
            }
        }
    },
    async mounted(){
        //console.log("MOUNTEdd")
        VueBus.$on('StoreItemsDelete',this.StoreItemsDeleteComplete)

        await this.LoadItems(false);

    },
    methods:{
        async LoadItems(overwrite){
          //var id = await this.$store.dispatch('fetchIdFromSlug', brandID );
          this.storeItems =  await this.$store.dispatch('fetchStoreItems', this.addr, overwrite );

          console.log(this.storeItems)
        },
        ShowCreate(){
            this.item = null;
            this.showCreate = true;
        },
        CloseCreate(){
            this.LoadItems();
            this.showCreate = false;
        },
        getDate(dateVal){
            return moment.unix(dateVal).fromNow();
        },
        getDateStr(dateVal){
            return moment(dateVal).fromNow();
        },
        GetStoreURL(cid){
            let url = "https://worker.hokr.workers.dev/api/storeimages/"+ cid
            return url;
        },
        EditItem(item){
            this.selectedItem = item;
            this.showCreate = true;            
        },


        DeleteItem(item){
            
            let url = "/user/WALLETID/store/" + item.id
            let params = {
            
            }
            let method = "DELETE"
            let action = "delete this item"
            let message = "This action cannot be undone."            
            let callback = "StoreItemsDelete"

            
            VueBus.$emit('ConfirmAction',action, method, url, params, message, null, callback);
            /*
            setTimeout(async () =>  {
                this.storeItems = await this.$store.dispatch('fetchStoreItems', this.addr.toUpperCase(), true )
            }, 1000);
            */
            //
        },

        ToggleItem(item){
            
            let url = "/user/WALLETID/store/" + item.id + "/toggle"
            let params = {
            
            }
            let method = "PUT"
            let action = "toggle visibility of this item"
            let message = "This action can be undone."
            let callback = "StoreItemsDelete"

            
            VueBus.$emit('ConfirmAction',action, method, url, params, message, null, callback);
            /*
            setTimeout(async () =>  {
                this.storeItems = await this.$store.dispatch('fetchStoreItems', this.addr.toUpperCase(), true )
            }, 1000);
            */
        },

        StoreItemsDeleteComplete(){
            console.log("Meow")
            this.LoadItems(true);
        },

        async ExpandPanel(item){
            console.log(item)
        },

    }
}
</script>