<template>
    <v-container class="fill-height" color="purple" style="margin-top:45px;">
        <v-row  class="fill-height">
            <v-col  class="fill-height">
                <v-card class="fill-height" style="background-color:white;">
                    <v-card-title>
                        My Purchases
                    </v-card-title>
                    <v-card-text>
                        <v-sheet v-if="purchases.length == 0">
                                No purchases have been happend yet.
                        </v-sheet>

                        <v-expansion-panels popout v-if="purchases.length > 0">
                            <v-expansion-panel
                                v-for="item in purchases" :key="item.txID">
                                <v-expansion-panel-header>{{ getDate(item.txDate) }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-container>
                                        <v-row>
                                            
                                            <v-col align="end" cols="12"><a target="new" :href="'https://algoexplorer.io/tx/' + item.txID">Tx</a></v-col>
                                        </v-row>
                                        <v-row v-for="product in item.items" :key="product.productID" style="border-top: 1px solid black;">
                                            <v-col>
                                                <v-avatar size="56">
                                                    <v-img 
                                                        :src="GetStoreURL(product.bgImage)"></v-img>
                                                </v-avatar>
                                            </v-col>
                                            <v-col align="start" class="text-subtitle-1">
                                                {{ product.title }}
                                            </v-col>
                                            <v-col align="end">
                                                {{ product.amount }} {{ product.asaName }}
                                            </v-col>
                                        </v-row>
                                        
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        
                    </v-card-text>
                </v-card>
                
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VueBus } from '../plugins/vuebus.js';
import * as moment from 'moment';


export default {
    name: "MyPurchases",
    props:["addr","sessionGuid","sessionTxID","action"],
    data: () => {
      return {
        purchases: [],
      }
    },
    watch:{
        addr(oldv, newv){
            if(oldv != newv){
                if(!this.addr){
                    VueBus.$emit("Nav","/brands")
                }
                this.LoadPurchases();

            }
        }
    },
    async mounted(){
        if(!this.addr){
            this.LoadPurchases();
        }
    },
    methods:{
        async GetFileName(item){
            console.log(item)
            //let x = await this.GetStoreItem();
            //return x.name;
            return "Bird"
        },
        async GetStoreItem(item){
            return await this.$store.dispatch('fetchStoreItem', item.sellerID, item.productID )
        },
        async LoadPurchases(){
            this.purchases = await this.$store.dispatch('fetchPurchases', this.addr.toUpperCase() )
            
        },
        getDate(dateVal){
            return moment.unix(dateVal).fromNow();
        },
        getStatus(status){
            switch(status){
                case 0:
                    return "new"
                case 1:
                    return "waiting"
                case 2:
                    return "complete"

            }
        },
        GetStoreURL(cid){
            let url = "https://worker.hokr.workers.dev/api/storeimages/"+ cid
            return url;
        },

    }
}
</script>