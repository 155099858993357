<template>
  <div style="height:100%;">
        <v-card class="fill-height  d-flex flex-column pb-10 pt-16" >
          <AddressBar
            :brand="myBrand"
            :sessionTxID="sessionTxID"
            :sessionGuid="sessionGuid"
            :walletType="walletType"
            :addr="addr"
          ></AddressBar>
          <v-card-text class="ma-0 pa-0">
          <v-layout justify-center fluid full-width class="fill-height" >
          <v-container fluid full-width class="fill-height">
            <v-row>
                <v-col class="pa-0 ma-0 fill-height">
                    
                    <router-view
                        :addr="addr"
                        :sessionTxID="sessionTxID"
                        :sessionGuid="sessionGuid"
                        :walletType="walletType"
                        class="fill-height"
                    ></router-view>
                    
                </v-col>
            </v-row>
        </v-container>
        <v-spacer></v-spacer>
        </v-layout>
        </v-card-text>
        </v-card>
        <v-footer
          fixed
          padless
        >
          <v-card
            tile
            flat
            width="100%"
            color="brown lighten-3"
            height="50px"
            class="d-flex align-center justify-center"
          >
            &copy; 2024 Rewadiply
          </v-card>
        </v-footer>
      
    </div>
    </template>
    
<script>
import { VueBus } from '../plugins/vuebus.js';
import AddressBar from "./Address-Bar.vue";
//import PostCreate from "./Post-Create.vue";

    export default {
        name: "BrandAll",
        props:["addr","sessionGuid","sessionTxID","walletType"],
        components:{
            AddressBar,
//            PostCreate
        },
        data: () => {
          return {
            slugs: {},
            myBrand: null,
            posts: [],
            following: false,
            drawer: false,
            showingBrandID: -1,
            ShowCreate: false,
          }
        },
        async mounted(){
          this.LoadMe();
          this.LoadBrandBySlug(this.$route.params.brandID)
        },
        watch: {
          ShowCreate(oldv, newv){
            console.log(oldv)
            console.log(newv)
          },
          addr(oldv, newv){
            if(oldv!=newv){
              this.LoadMe();
              this.LoadBrandBySlug(this.$route.params.brandID)
            }
          },
          '$route.params.brandID'(oldv, newv){
            if(oldv != newv){
              this.LoadBrandBySlug(this.$route.params.brandID)
            }
          },
        },
        methods:{
        async LoadMe(){
          if(this.addr){
            let brand = await this.$store.dispatch('fetchBrand', this.addr.toUpperCase() )
            this.myBrand = brand;
          }
        },
        ShowAdd(){
          if(this.myBrand && (this.myBrand.userType=="brand" || this.myBrand.userType=="admin") && this.addr == this.showingBrandID){
            return true
          } else {
            return false
          }
        },
        CloseCreate(){
          console.log(this.myBrand)
          this.ShowCreate = false;
          VueBus.$emit('UpdatePosts', this.myBrand.senderID);
        },
        ListMessages(){
    
        },
        async LoadBrandBySlug(brandID){
          //if no brand is set, cut it.
          if(!brandID){
            this.showBrandID = "";
            return
          }
          
          var firstLetter = brandID.charAt(0)
        
          var me = this;
          await fetch("https://worker.hokr.workers.dev/api/slugs/" + firstLetter )
              .then(r => r.json())
              .then(json => {
              me.slugs=json;
          })
          
          this.showingBrandID = this.slugs[this.$route.params.brandID].toUpperCase()
        },
      }
    }
    
    </script>
    
    <style>
    body {
      background-color:#fefefe;
      font-family:Poppins;
    }
    .v-card {
      background-color: #eee !important;
    }
    
    </style>