<template>
    <span>
        <v-container class="fill-height" color="purple">
            <v-row  class="fill-height">
                <v-col  class="fill-height">
                    <v-card class="fill-height" style="background-color:white; margin-top:45px;">
                        <v-card-title>
                            My Links/API
                        </v-card-title>
                        <v-card-text>
                            
                            <v-sheet v-if="links.length == 0">
                                No links have been added yet. Let's add a link to begin.
                            </v-sheet>
                            
                            <v-expansion-panels v-if="links.length > 0" popout>
                                <v-expansion-panel
                                    v-for="item in links" :key="item.id">
                                    <v-expansion-panel-header>
                                        {{ item.name }}
                                        <v-spacer></v-spacer>
                                        
                                        {{ GetFromNow(item.createdDate) }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-toolbar floating>
                                        
                                        
                                        
                                        <v-btn icon @click="EditItem(item)"><v-icon>mdi-lead-pencil</v-icon></v-btn>
                                        <v-btn icon @click="DeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                                        <v-btn icon @click="ActivateItem(item)" v-if="item.status=='NEW' || item.status=='INACTIVE'"><v-icon>mdi-eye-off</v-icon></v-btn>
                                        <v-btn icon @click="DeactivateItem(item)" v-if="item.status=='ACTIVE'"><v-icon>mdi-eye-outline</v-icon></v-btn>

                                    </v-toolbar>


                                        <v-container>
                                            <v-row>
                                                <v-col> {{ item.name }} <v-chip small label>{{ item.status }}</v-chip></v-col>
                                                <v-col align="end">Reward Amount: {{ item.perPerson }} {{ item.asaName }}</v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <div class="text-caption font-weight-bold">Redirected Link</div>

                                                    <v-chip small :color="GetColor(item.type)" label>{{ item.type }}</v-chip> URL: {{ item.url }}
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="item.type=='GET'">
                                                <v-col>
                                                    <div class="text-caption font-weight-bold">Replace <span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span> with the hashes of your email addresses</div>
                                                    <pre>https://links.hokr.workers.dev/x/{{ item.id }}/<span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span></pre>
                                                </v-col>    
                                            </v-row>
                                            <v-row v-if="item.type=='POST'">
                                                <v-col>
                                                    <div class="text-caption font-weight-bold">Replace <span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span> with the hashes of your email addresses</div>
                                                    <pre>https://links.hokr.workers.dev/x/{{ item.id }}</pre>
                                                    
                                                        
<pre style="background-color:#dddddd; border: 1px solid black; padding:5px; margin-top:15px;">{ 
    "email":"<span style="color:white; background-color:green;" class="font-weight-bold">SHA256</span>"
}</pre><span>JSON payload</span>

                                                    
                                                </v-col>    
                                            </v-row>

                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>
                    
                </v-col>
            </v-row>
        </v-container>

        <v-btn
            color="primary"
            dark
            @click="showCreate=true"
            fab
            fixed
            bottom
            right
            x-large
            >
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-dialog :value="showCreate" width="800">
            <v-card>
                <v-card-text>
                    <LinkCreate
                    :sessionGuid="sessionGuid"
                    :sessionTxID="sessionTxID"
                    :addr="addr"
                    :selectedLink="selectedLink"
                    :linkID="linkID"
                    @CloseCreate="CloseCreate"
                    >
                    </LinkCreate>
                </v-card-text>
            </v-card>
        </v-dialog>

    </span>
</template>

<script>
import LinkCreate  from './Link-Create.vue';
import { VueBus } from '../plugins/vuebus.js';
import * as moment from 'moment';

export default {
    name: "MyLinks",
    components:{
        LinkCreate
    },
    props:["addr","sessionGuid","sessionTxID","action"],
    data: () => {
      return {
        showCreate: false,
        selectedLink: null,
        linkID: "",
        links: [],
        address: null,
        note: "",
        notes: null,
        path: "https://worker.hokr.workers.dev/api/",
      }
    },
    watch:{
        addr(oldv, newv){
            if(oldv != newv){
                if(!this.addr){
                    VueBus.$emit("Nav","/brands")
                }
                this.LoadLinks();
            }
        }
    },
    async mounted(){
        await this.LoadLinks();
        VueBus.$on('LinkDelete',this.LinkDeleteComplete)

    },
    methods:{
        async LoadLinks(overwrite){
            this.links = await this.$store.dispatch('fetchLinks', this.addr.toUpperCase(), overwrite )

        },
        async CloseCreate(){
            this.showCreate = false;
            this.LoadLinks(true);
        },
        GetColor(type){
            if(type=="GET")
                return "green"
            else
                return "blue"
        },
        GetFromNow(dateStr){
            if(!dateStr)
                return "No date";

            return moment(dateStr).fromNow();
        },
        EditItem(item){
            this.selectedLink = item;
            this.linkID = item.id;

            this.showCreate = true;            
        },


        DeleteItem(item){
            
            let url = "/user/WALLETID/link/" + item.id
            let params = {
            
            }
            let method = "DELETE"
            let action = "delete this link"
            //let message = "This action cannot be undone."
            let callback = "LinkDelete"

            VueBus.$emit('MembershipGate',action, method, url, params, callback);
            //
        },
        ActivateItem(item){
            
            let url = "/user/WALLETID/link/" + item.id + "/activate"
            let params = {
            
            }
            let method = "PUT"
            let action = "toggle visibility of this item"
            let message = "This action can be undone."
            let callback = "LinkDelete"
            
            VueBus.$emit('ConfirmAction',action, method, url, params, message, null,  callback);
            
        },
        DeactivateItem(item){
            
            let url = "/user/WALLETID/link/" + item.id + "/deactivate"
            let params = {
            
            }
            let method = "PUT"
            let action = "toggle visibility of this item"
            let message = "This action can be undone."
            let callback = "LinkDelete"
            
            VueBus.$emit('ConfirmAction',action, method, url, params, message, null, callback);
            
        },

        LinkDeleteComplete(){
            this.LoadLinks(true);
        },



    }
}
</script>