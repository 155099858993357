<template>
    <v-container>
        <v-row>
            <v-col>

                <v-sheet color="white" class="pa-4 text-body">
                    <AuthIt></AuthIt>
                </v-sheet>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VueBus } from '../plugins/vuebus.js';
import AuthIt from './Auth-It.vue'

export default {
    name: "LoginCreate",
    props:["addr","sessionGuid","sessionTxID","action"],
    components:{
        AuthIt,
    },
    data: () => {
      return {
      }
    },
    methods:{
        CreateAccount(){
            var action, method, url, finish = "";
            var params = {}
            VueBus.$emit('MembershipGate',action, method, url, params, finish);

        }
    }
}
</script>