<template>  
    <v-app>
      <v-card>
      <v-toolbar 
         
        dense 
        elevate-on-scroll
        height="150">
        <v-spacer></v-spacer>
       <v-toolbar-title>
          <img src="../../src/assets/rewardiply-logo-med.png">
        </v-toolbar-title>
        
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!--  v-btn flat>FAQ</v-btn -->
          <!--<v-btn flat>Blog</v-btn>-->
          <!-- v-btn class="pink lighten-3" href="app">Sign In</v-btn -->
        </v-toolbar-items>
      </v-toolbar>
      </v-card>
      <v-main>
        <section>
          <v-parallax src="../../src/assets/apparel-1850804_1920.png" width="100%">
            
            <v-layout column align-center justify-center class="white--text">
              <h1 class="h2 white--text HOKRHeroHead" style="text-align:center; line-height:1; font-weight: 900; text-shadow: 3px 2px #000000; color:#FF9200 ">Claim your rewards</h1>
              <div class="white--text subheading mb-3 text-xs-center HOKRHeroSubHead" style="margin-top:15px; text-align:center; font-family:Poppins; font-weight: 900; text-shadow: 2px 2px #000000; font-style:italc;">( or extend your brand presence )</div>
              <v-btn @click="GetStarted()" class="mt-5" color="#FF9200" dark large style="margin-bottom:40px;" >
                Get Started
              </v-btn>
  
              
            </v-layout>
          </v-parallax>
        </section>
  
        
        <section class="white" style="padding-bottom:20px; " >
          <v-layout
            column
            wrap
            align-left
            width="100%"
            style="width:100%;"
          >
            <v-flex xs12 width="100%" style="width:100%;">
              <v-container grid-list-xl width="100%" style="width:100%;">
                <div style="color:#4A4141;">Message Statistics</div>
  
                <v-layout row wrap text-align-center>
                  <v-flex xs12 md4>
                      <div style="color:#4A4141;" class="text-h2 font-weight-bold text-center" >{{ stats.m }}</div>
                      <div dark style="white-space: nowrap; color:#4A4141;" class="text-uppercase text-h6 text-white font-weight-bold text-center">Messages Sent</div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div style="color:#4A4141;" class="text-h3 font-weight-bold text-center">{{FormatDate(stats.rt) }}</div>
                    <div dark style="white-space: nowrap; color:#4A4141;" class="text-uppercase text-h6 text-white font-weight-bold text-center">Read Time</div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div style="color:#4A4141;" class="text-h2 font-weight-bold text-center" >{{ stats.tr }}</div>
                    <div dark style="white-space: nowrap; color:#4A4141;" class="text-uppercase text-h6 text-white font-weight-bold text-center">Transfered Algos</div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </section>
        
        
        <section style="background-color:#dddddd">
          <v-container>
            <div class="text-xs-left HOKRSection">
                <h2 class="HOKRSubHead " style="color:#4A4141">Finally a loyalty app that rewards your loyalty</h2>
                <span class="HOKRText" style="text-align:left;">
                  Brands are tired of the treadmill where they work very hard to meet a customer, only to have that customer wooed away by a brand offering a better perceived deal. Your loyalty is worth something
                  and at Rewariply, we reward that loyalty! Get early access to special offers, better inventory or just have your voice heard.
                </span>
              </div>
  
          </v-container>
        </section>
        <section class="white" style="padding-bottom:20px;">
          <v-layout
            column
            wrap
  
            align-left
          >
            <v-flex xs12 sm4 class="my-3">
            </v-flex>
            <v-flex xs12>
              <v-container grid-list-xl>
                <v-layout row wrap align-left>
                  <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                      <v-card-text class="text-xs-center"  style="text-align:center; ">
                        <v-btn x-large class="blue--text text--lighten-2" fab ><v-icon>design_services</v-icon></v-btn>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline text-xs-center" style="color:##4A4141;">Read  content</div>
                      </v-card-title>
                      <v-card-text style="color:##4A4141;">
                        Brands want your attention, let them reward you for your time.
                      </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                      <v-card-text class="text-xs-center"  style="text-align:center;">
                        <v-btn x-large class="blue--text text--lighten-2" fab ><v-icon>insights</v-icon></v-btn>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline" style="color:#4A4141;">Engage</div>
                      </v-card-title>
                      <v-card-text style="color:#4A4141;">
                        Receive discounts, early acces  and extended product offering all while recieving reward points.
                      </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                      <v-card-text class="text-xs-center" style="text-align:center;">
                        <v-btn x-large class="blue--text text--lighten-2" fab><v-icon>payments</v-icon></v-btn>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline text-xs-center" style="color:#4A4141;">Get rewarded</div>
                      </v-card-title>
                      <v-card-text style="color:#4A4141;">
                        Redeem brand points for real things.
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </section>
  

  
        <section  style="padding-bottom:20px;background-color:#dddddd;">
          <v-container class="HOKRSection">
            <div class="HOKRSubHead" style="color:#4A4141">Technology Preview</div>
            <div class="HOKRText" style="text-align:left;">This is new technology and there will be issues, but you are free to use it. For more information, please follow the <a href="https://twitter.com/hokrxyz">HOKR twitter account.</a> For technical support,
            hop on the Ospreys.algo <a href="https://discord.gg/XvA56tkUdW">discord</a>. Remember that this is a small team trying to tackle big problems.</div> 
          </v-container>
        </section>
  
  
      <section class="white HOKRSection" style="color:#4A4141; text-align:center;" >      
        &copy; 2023 Rewardiply
      </section>
  
    </v-main>
  </v-app>
  </template>
  
  <script>
  import axios from "axios";
  import * as moment from 'moment'

  
  
  
  
  export default {
    name: "Rewardiply-Home",
    data: () => {
      return {
        stats: {
          m: 0,
          rt: 0,
          tr: 0,
        },
      }
    },
    async mounted(){
      await this.GetDeets();
    }, 
    methods: {
      async GetDeets(){
        let stats = await axios.get("https://stats.hokr.workers.dev/")
        this.stats = stats.data;
      },
      FormatDate: function (val) {
        var dur = moment.duration(val, 'seconds')
        return dur.humanize()
      },
      GetStarted(){
        this.$router.push("/brands")
      }
    }
  }
  
  </script>
  
  <style>
    .HOKRHeroHead{
      font-size: 72px;
      font-family:Lobster;
    }
    .HOKRHeroSubHead{
      font-size: 30px;
      font-family:Poppins;
    }
    .HOKRSubHead{
      font-size: 24px;
      font-family:Poppins;
      font-weight:bold;
      padding-top:30px;
      text-align:left;
    }
    .HOKRText{
      font-size: 18px;
      font-family:Poppins;
      color: black;
      text-align:left !important;
    }
  
    .HOKRSection{
      padding-top:40px;
      padding-bottom:40px;
    }
  
    #app {
    overflow: hidden;
    
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
  }
  
  #app canvas {
    display: block;
    position: fixed;
    z-index: -1;
    top: 0;
  }
  </style>
  