<template>
    <div>
        <v-container class="fill-height" color="purple">
            <v-row  class="fill-height">
                <v-col  class="fill-height">

                    <v-card fluid class="fill-height">
                        <v-card-title>Let's get started (Create a reward token)  
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <div class="text-caption" style="margin-bottom:20px;">
                                Loyalty points are the foundation of a rewards prgoram, in order for you to give loyalty points to your customers, you will need to either create a loyalty token, 
                                or have them sent to you. To get started, create a loyalty token. FYI, the tokens are stored on the blockchain, and as such can be traded between your customers.
                            </div>

                            <LoyaltyCreate
                                :sessionGuid="sessionGuid"
                                :sessionTxID="sessionTxID"
                                :addr="addr"
                                @CloseFT="CloseFT"
                            ></LoyaltyCreate>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="showWelcome" width="400">
            <v-card>
                <v-card-title>One more thing</v-card-title>
                <v-card-text>
                    {{ welcomeText }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="showWelcome=false">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import axios from "axios";
//import LoyaltyTokens from "../components/Loyalty-Tokens.vue";
import LoyaltyCreate from "../components/Loyalty-Create.vue";
import { VueBus } from "@/plugins/vuebus";


export default {
    //name: "WalletType",
    props:["addr","sessionGuid","sessionTxID","action", "message"],
    components:{
        LoyaltyCreate,
    },
    data: () => {
      return {
        userType: "",
        welcomeText: "",
        showWelcome: false,
        create: false,
      }
    },
    watch:{
        async addr(oldv, newv){
            if(oldv != newv){
                await this.LoadWelcome();
            }
        }
    },
    async mounted(){ 
        await this.LoadWelcome();
    },
    methods:{
        async LoadBrand(){
//            let brand = await this.$store.dispatch('fetchBrand', this.addr.toUpperCase() )
//            this.userType = brand.userType;
        },
        CloseFT(){
            VueBus.$emit("Nav", "/brands")
        },
        async LoadWelcome(){
            var tokens = await this.$store.dispatch('fetchTokens', this.addr );

            if(tokens.length==0){
                this.showWelcome = true;
                this.welcomeText = "One last thing, you need to to either create a rewards token or have one sent to you.";
            }
        }
    }
}
</script>