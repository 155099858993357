<template>
    <v-card>
        <v-card-title>
            <v-avatar size="36" class="mr-4"><v-img src="../../src/assets/rewardiply-drip-80.png" ></v-img></v-avatar>

            Hold on there, before we can {{ action }} we have one more step! 
        </v-card-title>
        <v-card-text>
            <v-expansion-panels popout v-model="OpenPanel" multiple active-class="ActivePanel">
                <v-expansion-panel>
                    <v-expansion-panel-header>Sign In</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <SignIn 
                            :action="action"
                            :addr="addr"
                            :sessionGuid="sessionGuid"
                            :sessionTxID="sessionTxID"

                            @AuthComplete="AuthComplete"
                        ></SignIn>
                    </v-expansion-panel-content>
                </v-expansion-panel>


                <v-expansion-panel>
                    <v-expansion-panel-header>Create Account</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <WalletCreate 
                        :addr="addr"
                        :sessionGuid="sessionGuid"
                        :sessionTxID="sessionTxID"    
                        :action="action"></WalletCreate>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- v-expansion-panel>
                    <v-expansion-panel-header>Recover Account</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <Recover :action="action"
                            :addr="addr"
                            :sessionGuid="sessionGuid"
                            :sessionTxID="sessionTxID"
                        ></Recover>
                    </v-expansion-panel-content>
                </v-expansion-panel -->

            </v-expansion-panels>
        </v-card-text>
    </v-card>
</template>

<script>
import WalletCreate from './Wallet-Create.vue'
//import Recover from './SignIn-Mneomonic.vue'
import SignIn from './SignIn-Creds.vue'

export default {
  name: "Auth-It",
  components:{ WalletCreate, SignIn },
  props: ["action","addr","sessionGuid","sessionTxID"],
  data: () => {
        return {
            OpenPanel: [0],
        }
    },
    mounted(){
        switch(this.$route.path){
            case "/login":
                this.OpenPanel = [0];
                break;
            case "/create":
                this.OpenPanel = [1];
                break;
        }
    },
    methods:{
        AuthComplete(d){
            this.$emit("AuthComplete", d);
        }
    }
}

</script>

<style>
.ActivePanel{
    font-weight:bold;
}
</style>