<template>
        <v-card color="#ffffff">
            <v-card-text>
                <div class="text-h4 pt-4">Create Store Item</div>
                <v-form  ref="form" class="pa-0 ma-0">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field 
                            
                            v-model="StoreItem.amount"
                            label="Amount"/></v-col>
                        <v-col>
                            <v-select 
                                @change="SelectToken"
                                :rules="[rules.required]"
                                v-model="selectedTokens"
                                solo
                                :items="tokens"
                                item-text="asset.name"
                                item-value="asset.assetId"
                                return-object
                                label="Reward Token"></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field 
                                v-model="StoreItem.title"
                                :rules="[rules.required]"
                                label="Title">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="text-subhead">Product Image (800x800) <span style="color:red; ">{{ ImageError }}</span></div>
                            <v-img
                            class="text-center"
                            justify="center"
                            @click="document.getElementById('bgUploader').click()"
                            style="position:relative; background-color:#dddddd"
                            
                            aspect-ratio="1"
                            max-width="100%"
                            :src="SrcURL" >
                                <v-chip
                                        style="margin-top:44px;"
                                        label
                                        x-small
                                        color="white"
                                        outlined
                                        text-color="white"
                                        >
                                        Select
                                        </v-chip>
                            </v-img>
                            <div style="display:none;">
                            <v-file-input 
                                id="bgUploader"
                                accept="image/*"
                                prepend-icon="mdi-page-layout-header"
                                label="Background"
                                @change="SelectImage"
                                @click:clear="ClearImage"
                                v-model="bgImage" />
                            </div>

                        </v-col>
                    </v-row>  
                    <v-row>
                        <v-col>
                            <v-textarea
                                outlined
                                :rules="[rules.required]"
                                v-model="StoreItem.description"
                                name="input-7-4"
                                label="Description"
                                :value=StoreItem.description
                                ></v-textarea>
                        </v-col>
                    </v-row>  
                </v-container>
                </v-form>

            </v-card-text>
            <v-card-actions class="pa-8 pt-0">
                <v-spacer></v-spacer>
                <v-btn @click="Save">Save</v-btn>
                <v-btn @click="HideCreate">Cancel</v-btn>

            </v-card-actions>
        </v-card>
</template>

<script>
import axios from "axios";

export default {
    name: "CreateStoreItem",
    props:["addr","sessionGuid","sessionTxID","item"],
    data: () => {
        return {
            document,
            StoreItem: {},
            path: "https://worker.hokr.workers.dev/api/",
            bgImage: [],
            ImageError:"",
            bgURL: "",
            SrcURL: "",
            tokens:[],
            selectedTokens: null,
            rules: {
            required: value => !!value || 'Required.',
            url: value => {
                const urlRegex = /^https:\/\.*/i;            
                return urlRegex.test(value) || 'Invalid URL'
            },
            counter: value => value.length <= 20 || 'Max 20 characters',
            numeric: value  => {
            if(value)
            {
                if (!value.trim()) return true;
                if (!isNaN(parseFloat(value)) ) return true;
            }
            return 'Must be a number';
            },            
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            mobile: value => {
                const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
                return pattern.test(value) || 'Invalid mobile.'
            },
            },
        }
    },
    async mounted(){
        this.tokens = await this.$store.dispatch('fetchTokens', this.addr );
        console.log(this.tokens)
        if(this.item){
            
                    
                    this.selectedTokens ={
                    amount: 100,
                    asset: {
                        assetId:  "" + this.item.asaID,
                        name:     this.item.asaName,
                        decimals: this.item.decimals
                    }
                }
                
                
                //this.selectedTokens = ["1287717590"]

            console.log("TOKEN")
            console.log(this.selectedTokens)
            this.StoreItem = this.item;
            this.GetURL();

        }
    },
    watch:{
        'item.id'(oldv, newv){
            if(oldv != newv){
                if(this.item){
                    
                    
                    this.selectedTokens = {
                    amount: 100,
                    asset: {
                        assetId:  "" + this.item.asaID,
                        name:     this.item.asaName,
                        decimals: this.item.decimals
                    }
                }
                
               this.selectedTokens = ["1287717590"]

                this.StoreItem = this.item;
                this.GetURL();

                }
            }
        },
        async addr(oldv, newv){
            if(oldv!= newv){
                this.tokens = await this.$store.dispatch('fetchTokens', this.addr );
                
            }
        }
    },
    methods:{
        async SelectImage(image){
            this.StoreItem.bgImage = image;
            this.StoreItem.bgURL = URL.createObjectURL(this.StoreItem.bgImage);
        },
        ClearImage(){
            this.bgImage = null;
            this.bgURL = "";
        },
        GetURL(){
            var cid = this.StoreItem.bgImage
            
            
            if(cid && cid.startsWith("blob:")){
                this.SrcURL = cid;
                return cid;
            }

            this.SrcURL = "https://worker.hokr.workers.dev/api/storeimages/"+ cid
            console.log(this.SrcURL)
            return this.SrcURL;
        },
        HideCreate(){
            this.$emit("HideCreate");
        },
        SelectToken(selectedItem){
            console.log("SELECTING")
            console.log(selectedItem)

            this.StoreItem.asaID = parseInt(selectedItem.asset.assetId);
            this.StoreItem.asaName = selectedItem.asset.name;
            this.StoreItem.decimals = selectedItem.asset.decimals;
        },
        async Save(){
            this.ImageError = "";
            if(!this.$refs.form.validate() || this.StoreItem.bgImage == null){
                if(this.StoreItem.bgImage==null) this.ImageError = "An image is required";
                return false;
            }

            if(typeof this.StoreItem.bgImage =="object" && 
                this.StoreItem.bgImage.type &&
                ( this.StoreItem.bgImage.type == "image/png" ||
                this.StoreItem.bgImage.type == "image/jpg" ||
                this.StoreItem.bgImage.type == "image/jpeg" ||
                this.StoreItem.bgImage.type == "image/gif" 
                )
            ){
                //upload image and replace with aCID
                var cid = await this.PostImageForCID(this.StoreItem.bgImage)
                this.StoreItem.bgImage = cid;
            }

            //new records get cool stuff
            if(!this.StoreItem.id){
                var id=crypto.randomUUID();
                this.StoreItem.id=id;
                this.StoreItem.createdDate = new Date();
                this.StoreItem.status = "active"
            }

            if(typeof this.StoreItem.amount == "string"){
                this.StoreItem.amount = parseFloat(this.StoreItem.amount)
            }


            var headers = {
                    'sessionGuID': this.sessionGuid,
                    'sessionTxID': this.sessionTxID,
                    'walletID': this.addr,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }

            
                
            let url = this.path + "user/" + this.addr + "/store"
            var me = this;

            axios.post(url, this.StoreItem, { headers: headers} )
                .then(async function (response) {
                
                me.HideCreate();
                console.log(response.data)
            })
            .catch(function(error){
                console.log("Error on request")
                console.log(error)
            })

        },
        async PostImageForCID(img){
            var message_payload = {
                uploadedImage: img
            }

            var headers = {
                    'sessionGuID': this.sessionGuid,
                    'sessionTxID': this.sessionTxID,
                    'walletID': this.addr,
                    'view': "savestoreimage",
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }

            return await axios.post("/.netlify/functions/Message",message_payload, { headers: headers} )
                .then(async function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
            });

        },              
    }
}

</script>