<template>
    <v-container class="fill-height" color="purple" style="margin-top:45px;">
        <v-row  class="fill-height">
            <v-col  class="fill-height">

                <v-card fluid class="fill-height">
                    <v-card-title>Loyalty Points  
                        <v-spacer></v-spacer>
                        <v-dialog v-model="create">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            small
                            v-bind="attrs"
                            v-on="on"
                            >
                            Add loyalty points
                            </v-btn>
                        </template>
                        <LoyaltyCreate
                            :sessionGuid="sessionGuid"
                            :sessionTxID="sessionTxID"
                            :addr="addr"
                            @CloseFT="CloseFT"
                        ></LoyaltyCreate>
                    </v-dialog>
                    </v-card-title>
                    <v-card-text>
                        <div class="text-caption" style="margin-bottom:20px;">
                            Loyalty points are the foundation of a rewards prgoram, in order for you to give loyalty points to your customers, you will need to either create a loyalty token, 
                            or have them sent to you. To get started, create a loyalty token.
                        </div>

                        <LoyaltyTokens
                            :sessionGuid="sessionGuid"
                            :sessionTxID="sessionTxID"
                            :addr="addr"
                        ></LoyaltyTokens> 
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
//import axios from "axios";
import LoyaltyTokens from "../components/Loyalty-Tokens.vue";
import LoyaltyCreate from "../components/Loyalty-Create.vue";


export default {
    //name: "WalletType",
    props:["addr","sessionGuid","sessionTxID","action"],
    components:{
        LoyaltyTokens,
        LoyaltyCreate,
    },
    data: () => {
      return {
        userType: "",
        create: false,
      }
    },
    watch:{
        addr(oldv, newv){
            if(oldv != newv){
                this.LoadBrand();
            }
        }
    },
    mounted(){      

    },
    methods:{
        async LoadBrand(){
            let brand = await this.$store.dispatch('fetchBrand', this.addr.toUpperCase() )
            console.log(brand)
            this.userType = brand.userType;
        },
        CloseFT(){
            this.create = false;
        },
/*
        Save(){
            var message_payload = {
                userType: this.userType,
            }
            var me = this;
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
            }

            var url = "https://worker.hokr.workers.dev/api/user/"+ this.addr +"/usertype"

            axios.post( url,message_payload, { headers: headers} )
                .then(async function (response) {
                if(response.data == "OK"){
                    console.log(response.data)
                    console.log(me)
                    //me.$emit("save", true, "Save was successful")
                }  else {
                    //me.$emit("save", false, "Something went wrong")
                }

                console.log(response.data)
            })
        } 
        */
    }
}
</script>