<template>
    <v-sheet color="white" class="pa-4 text-body">
        <div style="margin-top:12px;font-family:Lobster; font-size:24px; color:#373232; margin-bottom:10px;">Welcome to Rewardiply</div>

        <div>
        Welcome to Rewardiply, a loyalty platform where you get reward points 
        for interacting with the brand, not just when you buy something. Please
        feel free to click around and find brands that you'll love. 
        </div>
        <div style="margin-bottom:10px;">
        Rewardiply is a rewards-driven application, so in order to reap all of the 
        benefits, you will have to create and account
        </div>

        <v-container>
            <v-row>
                <v-col class="text-center">
                    <v-btn @click="CreateAccount">Create Account</v-btn>
                </v-col>
                <v-col class="text-center">
                    <v-btn @click="SignIn">Login</v-btn>
                </v-col>

            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import { VueBus } from '../plugins/vuebus.js';


export default {
    name: "WelcomeBanner",
    props:["addr","sessionGuid","sessionTxID","action"],
    data: () => {
      return {
      }
    },
    methods:{
        CreateAccount(){
            VueBus.$emit("Nav", "/create" )
        },
        SignIn(){
            VueBus.$emit("Nav", "/login" )
        },

    }
}
</script>