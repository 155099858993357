<template>
<div>
<v-card class="fill-height">

<v-app-bar dense color="#3B3059" height="30" >
  <span style="margin-top:20px;">
    <v-checkbox
    v-model="following"
    
    @change="ToggleFollow"
    flat
    dark
    label="Following"
    ></v-checkbox>
  </span>
  
  <v-spacer></v-spacer>

  <v-btn v-if="brand.linkedIn" :href="GetLinkedin(brand.linkedIn)" icon >
    <v-icon color="white" medium>mdi-linkedin</v-icon>
  </v-btn>

  <v-btn v-if="brand.twitter" :href="GetTwitter(brand.twitter)" icon  >
    <v-icon color="white" medium>mdi-twitter</v-icon>
  </v-btn>

  <v-btn v-if="brand.emailSignup" :href="brand.emailSignup" icon  >
    <v-icon color="white" medium>mdi-email</v-icon>
  </v-btn>

</v-app-bar>

<v-container style="background-color:#fefefe;" class="fill-height" max-width="1000" >
<v-row>
  <v-col class="pa-0 ma-0 text-left">
    <v-img
      class="text-center"
      justify="center"
      style="position:relative; background-color:#dddddd"
      height="150"
      :src="GetURL(brand.backgroundCid)" >
    </v-img>
      <v-avatar width="106" height="106" color="#777777"                             
        style="margin-top:-50px; margin-left:30px;position:absolute;"
      >
    <v-avatar width="100" height="100" >
      <v-img :src="GetURL(brand.logoCid)" @click="location.href='brand.url'"></v-img>
    </v-avatar>
  </v-avatar>

  </v-col>
</v-row>
<!-- Provides the application the proper gutter -->
      <v-row>
        <v-col class="pa-0 ma-0">  
          <div style="font-family:Poppins; background-color:#ccc; padding:20px; font-style:italic; text-align:left;padding-left:200px;">
            {{ brand.description }}
          </div>
        </v-col>
      </v-row>
      <v-row class="fill-height">
          <v-col class="pa-0 ma-0 fill-height" >
            <v-breadcrumbs
              :items="breadCrumbs"
              divider="|"
            >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item style="cursor:pointer;" @click="GetLinkPath(item.text)">
                {{GetLinkName(item.text)}}
              </v-breadcrumbs-item>
            </template>

            </v-breadcrumbs>
            <div class="fill-height" style="border-top:1px solid #d5d5d5">
              
              <v-container>
                <v-row>
                  <v-col cols="12" class="v-col-12">




              <div style="padding-left:20px; margin-top:12px;font-family:Lobster; font-size:30px; color:#373232; margin-bottom:10px;">
                Store
                <v-dialog
                            v-model="dialog"
                            width="500"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="black"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                icon
                                >
                                <v-icon>mdi-qrcode</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                QR Code
                                </v-card-title>

                                <v-card-text>
                                <v-img :src="GetQR()"></v-img>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="dialog = false"
                                >
                                    OK
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
              </div>
              <div style="padding-left:20px;" class="text-caption">Let's reddeem those points</div>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" :md="GetSoloSize()" v-for="(item, key) in displayItems" :key="key">
                    <v-card>
                      <v-card-title style="font-weight:bold; height:80px;" @click="GetItem(item)" class="text-h5">{{ item.title }}                 
                        <v-dialog
                            v-model="dialog"
                            width="500"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="black"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                icon
                                >
                                <v-icon>mdi-qrcode</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                QR Code
                                </v-card-title>

                                <v-card-text>
                                <v-img :src="GetQRItem(item)"></v-img>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="dialog = false"
                                >
                                    OK
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                          
                          </v-card-title>
                      <v-card-text>

                        <v-app-bar color="grey darken-2">
                          <div style="color: white; font-size:16px; font-weight:bold;"><v-icon color="white">mdi-currency-usd</v-icon> {{ item.amount }} {{ item.asaName }}</div>
                        </v-app-bar>

                          <v-card
                          class="pa-4"
                          flat
                          height="500"
                          :img="GetStoreURL(item.bgImage)"
                        >
                          <!-- v-app-bar floating class="float-right">
                              <div style="font-size:48px; font-weight:bold; margin-bottom:5px;">{{ item.amount }}</div>
                              <div style="font-size:16px; font-weight:bold;">{{ item.asaName }}</div>
                          </v-app-bar -->
                        </v-card>
                        <!-- v-img 
                            aspect-ratio="1"
                            max-width="100%"
                            :src="GetStoreURL(item.bgImage)"></v-img -->
                      
                        <v-container class="ma-0 pa-0">

                          <v-row>
                            <v-col class="v-col-12">


                              <div class="text-h6" style="margin-top:20px; min-height:140px;">{{item.description}}</div>
                            </v-col>
                            <!-- v-col class="v-col-3">
                              <div style="text-align:center;margin-top:30px;">
                                <div style="font-size:54px; font-weight:bold; margin-bottom:5px;">{{ item.amount }}</div>
                                <div style="font-size:16px; font-weight:bold;">{{ item.asaName }}</div>
                              </div>
                            </v-col -->
                          </v-row>
                          <v-row>
                            <v-col cols="12" align="center">
                              <v-btn class="primary" x-large v-if="addr!=''" @click="BuyItem(item)">Buy</v-btn>
                              <v-btn class="primary" x-large v-else @click="Login">Login/Register</v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                          
                      </v-card-text>

                    </v-card>
                  </v-col>
                </v-row>
              </v-container>

              <v-card v-if="Object.keys(storeItems).length==0 && showOne==false">
                <v-card-title>Ummm.... This is awkward.</v-card-title>
                <v-card-text>
                  This store is not yet setup. The owner has been notified that you are interested. If you are the owner, click here to add your first item.
                </v-card-text>
              </v-card>


            </v-col>
                  <!-- v-col cols="3" class="v-col-3">
                    <div style="margin-top:12px;font-family:Lobster; font-size:18px; color:#373232; margin-bottom:10px;">My Points</div>
                    <div style="background-color:#f5f5f5; border: 1px solid #d5d5d5">
                      <LoyaltyTokens
                        :sessionGuid="sessionGuid"
                        :sessionTxID="sessionTxID"
                        :addr="addr"
                      ></LoyaltyTokens> 
                  </div>
                  </v-col -->
                </v-row>
              </v-container>


            </div>
              
          </v-col>
      </v-row>
  </v-container>

  <!-- v-speed-dial v-if="addr==storeID">
      <template v-slot:activator>

        <v-btn
          fixed
          fab
          bottom
          right
          dark
          @click="ShowCreate"
          class="green lighten-1"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      </v-speed-dial --> 

    </v-card>

    <!-- v-dialog v-model="showCreate">
        <CreateStoreItem
            :addr="addr"
            :sessionTxID="sessionTxID"
            :sessionGuid="sessionGuid"
            @HideCreate = "showCreate=false"
        ></CreateStoreItem>
    </v-dialog -->
    </div>
</template>

<script>
//import store from '@/plugins/vuex';
import { VueBus } from '../plugins/vuebus.js';
//import CreateStoreItem from './Create-Store-Item.vue';
//import LoyaltyTokens from "./Loyalty-Tokens.vue";


export default {
    name: "StoreView",
    props:["addr","sessionGuid","sessionTxID"],
    //components: { CreateStoreItem },
    data: () => {
      return {
            storeID: null,
            dialog: false,
            following: false,
            showCreate: false,
            showOne: false,
            storeItemID: null,
            brand: {},
            walletType: "",
            path: "https://worker.hokr.workers.dev/api/",
            postCounter: 0,
            storeItems: {},
            displayItems: {},
            breadCrumbs: [
                {
                text: 'home',
                disabled: false,
                href: '/',
                },
                {
                text: 'brand',
                disabled: false,
                href: '/1',
                },
                {
                text: 'store',
                disabled: false,
                href: '/2',
                }                
            ]        
           
        }
    },
    async mounted(){

      this.storeID = await this.$store.dispatch('fetchIdFromSlug', this.$route.params.brandID )
      this.storeItemID = this.$route.params.storeItemID;
      console.log(this.storeItemID)
      await this.LoadBrand(this.$route.params.brandID)
      await this.LoadItems(this.$route.params.brandID)

      this.LoadView(this.storeItemID)
      

    },
    watch: {
      async '$route.params.brandID'(oldv, newv){
        if(oldv != newv){
          await this.LoadBrand(this.$route.params.brandID)
          await this.LoadItems(this.$route.params.brandID)
        
          console.log("GRI")
          console.log(this.storeItems)
        }
      },
      addr(oldVal, newVal){
        if(oldVal != newVal){

          if(this.brand){
            this.CheckFollow(this.brand.senderID);
          }
        }
      },
      '$route.path'(oldv, newv){
        console.log("payt")
        console.log(oldv)
        console.log(newv)
        console.log(this.$route.params.storeItemID);

        this.storeItemID = this.$route.params.storeItemID;
        this.LoadView(this.storeItemID)
      },
    },
    methods:{
        LoadView(storeItemID){
          let item = this.storeItems[storeItemID]

          if(storeItemID){
            this.showOne = true;
            this.displayItems = {};
            this.$set(this.displayItems, storeItemID, item)
          } else {
            this.showOne = false;
            this.displayItems = {};
            this.displayItems = this.storeItems;
          }

          //prompt the purchase
          if(this.$route.name=="storebuy"){
            this.BuyItem(item)
          }

        },
        ShowCreate(){
            this.showCreate = true;
        },
        async LoadItems(brandID){
          var id = await this.$store.dispatch('fetchIdFromSlug', brandID );
          this.storeItems =  await this.$store.dispatch('fetchStoreItemsVisible', id, true );

        },

        async LoadBrand(brandID){

            var id = await this.$store.dispatch('fetchIdFromSlug', brandID );
            this.brand = await this.$store.dispatch('fetchBrand', id );

            this.CheckFollow(id);
        },
        async CheckFollow(id){
            this.following = await this.$store.dispatch('fetchFollow', id )
        },
        ToggleFollow(){
            let url = "/user/WALLETID/toggle"
            let params = {
                followeeID: this.brand.senderID
            }
            let method = "POST"
            let action = "follow that brand"
            
            VueBus.$emit('MembershipGate',action, method, url, params);
        },
        GetURL(cid){
            let url = "https://worker.hokr.workers.dev/api/brandimages/"+ cid
            return url;
        },
        GetStoreURL(cid){
            let url = "https://worker.hokr.workers.dev/api/storeimages/"+ cid
            return url;
        },

        GetTwitter(twitter){
            return "https://twitter.com/" + twitter
        },
        GetLinkedin(linkedin){
            return linkedin
        },
        parseBool(val){
            (/true/).test(val)
        },
        GetLinkName(path){
      switch(path){
        case "home":
          return "Home"
        case "brand":
          return this.brand.brandName
        case "store":
          return "Store"
          //return this.$route.params.brandID
        case "item":
        if(this.post.message.header == "")
          return "Item"
        else  
          return this.post.message.header
      }
    },
    GetLinkPath(path){
      var retpath = ""
      switch(path){
        case "home":
          retpath = "/brands";
          break;
        case "store":
          retpath = "/brands/" + this.$route.params.brandID + "/store";
          break;
        case "brand":          
          retpath = "/brands/" + this.$route.params.brandID
          break;
        case "item":
          retpath =  "/brands/" + this.$route.params.brandID + "/post/" + this.$route.params.postID
          break;
      }

      VueBus.$emit("Nav", retpath);

      //return "{ params: { path: '"+ retpath +"',exact:true  }}"
      return retpath;
    },
    /*
    async postData(url = "", method="GET", data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: method, // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
      },*/
      BuyItem(item){
        let url = "/user/WALLETID/purchase"
        let params = {
          sellerID: this.brand.senderID,
          productID: item.id
        }
        let method = "POST"
        let action = "purchase the reward, '"+item.title+"'";

        let message = "The brand will be notifed and you should be hearing from them soon."
        
        VueBus.$emit('ConfirmAction',action, method, url, params, message, "address");

      },
      Login(){
        let url = "";
          let params = {}
          let method = ""
          let action = ""

          VueBus.$emit('MembershipGate',action, method, url, params);
      },
      GetQR(){
      let path = "https://rewardiply.com/brands/" + this.$route.params.brandID + "/store"
      let url = "https://worker.hokr.workers.dev/qr/" +   encodeURIComponent(path)

      return url;
    },
    GetQRItem(val){
      let path = "https://rewardiply.com/brands/" + this.$route.params.brandID + "/store/" + val.id + "/buy";
      let url = "https://worker.hokr.workers.dev/qr/" +   encodeURIComponent(path)

      return url;
    },
    GetSoloSize(){
      if(this.showOne){
        return 12
      } else{
        return 6;
      }
    },
    GetItem(item){
      console.log( this.$route)
      VueBus.$emit("Nav", "/brands/" + this.$route.params.brandID + "/store/" + item.id)
    }

    }

}
</script>