<template>
    <div>
    <v-container>
        <v-row>
            <v-col>
                <div class="text-h5">Create Link/API</div>

                <template>
                    <v-stepper
                        v-model="activeStep"
                        vertical
                    >
                        <v-stepper-step
                        :complete="activeStep > 1"
                        step="1"
                        >
                        Details
                        <small>you can embed params</small>
                        </v-stepper-step>

                        <v-stepper-content step="1">
                            <v-card>
                                <v-card-text>

                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            v-model="linkName"
                                            label="Name"
                                            @change="ValidateStep"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-btn-toggle
                                            v-model="toggle_exclusive"
                                            @change="PickType"
                                            mandatory
                                            >
                                            <v-btn class="wideButton">
                                                <v-icon>mdi-link</v-icon>
                                                Hyperlink
                                            </v-btn>
                                            <v-btn class="wideButton">
                                                <v-icon>mdi-api</v-icon>
                                                API Call
                                            </v-btn>
                                        </v-btn-toggle>

                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            v-model="linkURL"
                                            label="Redirect URL"
                                            prepend-icon="mdi-web"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                @click="Advance(2)"
                                :disabled="linkSetupDone"
                            >
                                Continue
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                        </v-stepper-content>


                        <v-stepper-step
                        :complete="activeStep > 2"
                        step="2"
                        >
                        Reward Type
                        </v-stepper-step>

                        <v-stepper-content step="2">

                        <v-card full-width>
                        <v-card-title>What you are sending?</v-card-title>
                        <v-card-text>



                        Reward Token: 
                        <v-select
                        v-model="defaultAlgo"
                        :items="tokens"
                        return-object
                        @change="SelectToken"
                        >
                        <template v-slot:selection="{ item }">
                            {{ item.asset.name }}<v-chip text-color="white" color="grey" small
                                >{{ item.asset.assetId }}</v-chip>
                        </template>

                        <template v-slot:item="{  item, attrs, on }">
                                    <v-list-item v-on="on" v-bind="attrs" >
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            <v-row no-gutters align="center">
                                            <span>{{ item.asset.name }}</span>
                                            <v-spacer></v-spacer>
                                            <v-chip text-color="white" color="grey" small
                                            >ASA ID:{{ item.asset.assetId }}</v-chip>
                                            </v-row>
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </template>
                        </v-select>

                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text
                            @click="activeStep = 1">
                            Back
                        </v-btn>

                        <v-btn
                            color="primary"
                            @click="activeStep = 3"
                            :disabled="asaSelectedDone"
                        >
                            Continue
                        </v-btn>

                        </v-card-actions>
                        </v-card>
                        </v-stepper-content>


                        <v-stepper-step
                        :complete="activeStep > 3"
                        step="3"
                        >
                        Amount
                        </v-stepper-step>

                        <v-stepper-content step="3">

                        <v-container>
                        <v-row>
                            <v-col>The Reward</v-col>
                            <v-spacer></v-spacer>
                            <v-col align="right" class="blue-grey--text text--lighten-4">{{ totalReward }} {{ sendAsa }}</v-col>
                        </v-row>
                        </v-container>


                        <v-card full-width>
                        <v-card-title>Reward Amount</v-card-title>
                        <v-card-text>
                        <v-container>
                        <v-row>
                            <v-col  class="col-12">
                            Per reward
                            <v-text-field
                                v-model="perPerson"
                                :suffix="sendAsa"
                                @change="CheckReward"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        </v-container>


                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text
                            @click="activeStep = 2">
                            Back
                        </v-btn>
                        <v-btn
                        color="primary"
                            @click="activeStep = 4"
                            :disabled="rewardAmountDone"
                        >
                            Continue
                        </v-btn>

                        </v-card-actions>
                        </v-card>
                        </v-stepper-content>

                        <v-stepper-step
                        :complete="activeStep > 4"
                        step="4"
                        >
                        Review
                        </v-stepper-step>

                        <v-stepper-content step="4">

                            <v-container>
                            <v-row>
                                <v-col>Review</v-col>
                                <v-spacer></v-spacer>
                                
                            </v-row>
                            </v-container>


                            <v-card full-width>
                            <v-card-title>Reward Amount</v-card-title>
                            <v-card-text>
                            <v-container>
                            <v-row>
                                <v-col  class="col-12">
                                    Name: {{ linkName }}<br/>
                                    Link Type: {{ linkType?"GET":"POST" }}<br/>
                                    URL: {{ linkURL }}<br/>
                                    Payment: {{ perPerson }} {{ asaName }}/reward<br/>

                                </v-col>
                            </v-row>
                            </v-container>


                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text
                                @click="activeStep = 3">
                                Back
                            </v-btn>

                            </v-card-actions>
                            </v-card>
                            </v-stepper-content>

                    </v-stepper>
                </template>
                <v-container>
                    <v-row>
                    <v-col align="end">
                    <v-spacer></v-spacer>
                    <v-btn
                    :disabled="IsSaveDisabled()"
                    style="margin-right:20px;"
                    color="primary"
                    @click="Save"
                    >
                        Save
                    </v-btn>

                    <v-btn
                    @click="Cancel"
                    >
                        Cancel
                    </v-btn>
                    
                    </v-col>
                    </v-row>
                </v-container>
            
            </v-col>
        </v-row>

    </v-container>
    </div>  
    
</template>

<script>
import { VueBus } from '../plugins/vuebus.js';


export default {
name: "LinkCreate",
    props: ["addr","sessionGuid","sessionTxID","selectedLink", "linkID"],
    data: () => {
    return {
        toggle_exclusive: undefined,
        activeStep:1,
        tokens:[],
        perPerson: "0",
        sendAsa:"",
        asaID: "",
        asaName: "",
        decimals: 0,
        totalReward:0.0,
        defaultAlgo:[],
        linkName: "",
        linkType: -1,
        linkURL: "",
        linkSetupDone: true,
        asaSelectedDone: true,
        rewardAmountDone: true,

    }


    },
    mounted(){
        
        if(this.linkID){
            this.linkName = this.selectedLink.name;
            this.linkURL = this.selectedLink.url;
            this.perPerson =  this.selectedLink.perPerson;
            this.rewardAmountDone = false;
            
            switch(this.type){
                case "POST": 
                    this.toggle_exclusive = 1;
                    break;
                case "GET":
                    this.toggle_exclusive = 0;
                    break;
            }
            this.ValidateStep();
        }

        this.LoadTokens();
        VueBus.$on('LinkCreate',this.LinkCreateComplete)
    }, 
    watch: {
        // whenever addr changes, this function will run
        addr: async function(newAddr, oldAddr) {
            if(newAddr != oldAddr){

                this.LoadTokens()
            }
        },
        linkID:  function(newv, oldv){
            
            if(newv != oldv){
                console.log("Link change")
                this.linkName = this.selectedLink.name;
            }
        },

    },
    methods: {
        ShowCreate(){
            console.log("show create")
            this.create = true;
        }, 
        HideCreate(){
            this.$emit("CloseCreate")
        },
        Advance(step){
            //if(this.activeStep>=step)
            this.activeStep = step

            console.log(this.activeStep)

        },
        async LoadTokens(){
            let tokens = await this.$store.dispatch('fetchTokens', this.addr );
            var me = this;
            
            if(this.selectedLink){
                var m = tokens.find(function(asa){
                    return asa.asset.assetId == me.selectedLink.asaID;
                })
                this.defaultAlgo = m;
                this.asaID = m.asset.assetId;
                this.asaName = m.asset.name;
                this.decimals = m.asset.decimals;
                this.asaSelectedDone = false;
            }
            

            this.tokens  = tokens;
        },
        SelectToken(selectedItem){
            
            this.asaID = selectedItem.asset.assetId;
            this.asaName = selectedItem.asset.name;
            this.decimals = selectedItem.asset.decimals;

            this.AsaSelected();
        },
        PickType(item){
            console.log(item)
            this.linkType = item;
            this.ValidateStep();
        },
        ValidateStep(){
            if(this.linkType == -1 || this.linkName == ""){
                this.linkSetupDone = true;
            } else {
                this.linkSetupDone = false;
            }
        },
        AsaSelected(){
            if(this.asaID == ""){
                this.asaSelectedDone = true;
            } else {
                this.asaSelectedDone = false;
            }
        },
        CheckReward(){
            if(this.perPerson == "" || this.perPerson == "0"){
                this.rewardAmountDone = true;
            } else {
                this.rewardAmountDone = false;
            }
        },
        async Save(){
            var guid;
            var status;
            
            //new/update
            if(!this.linkID){
                guid = await this.$store.dispatch('fetchGuid' )
                status = "NEW"
            }
            else{
                guid = this.linkID;
                status = "INACTIVE"
            }

            let sender = this.addr;

            let payload = {
                id: guid,
                creator: sender,
                name: this.linkName,
                status: status,
                type: (this.linkType==0)?"GET":"POST",
                url: this.linkURL,
                perPerson: parseFloat(this.perPerson),
                asaID: parseInt(this.asaID),
                asaName: this.asaName,
                decimals: this.decimals,
                //createdDate: new Date(),
            }
            let url = "/user/" + sender + "/link/"+guid;
            let params = payload
            let method = "POST"
            let action = "save this link"
            let callback = "LinkCreate"

            VueBus.$emit('MembershipGate',action, method, url, params, callback);

        },
        IsSaveDisabled(){
            if(this.activeStep >= 4 ){
                return false;
            } else  {
                return true;
            }

        },
        LinkCreateComplete(){
            console.log("LINK CREATE")
            this.$emit("CloseCreate")
        },
        Cancel(){
            this.$emit("CloseCreate")
        }
    }
};
</script>

<style>
    .wideButton{
        width:200px;
    }
</style>

